<template>
    <ImageViewer
        v-model:show="showImageViewer"
        :image-url="audit_data.SignedUrl"
        :width="800"
        :height="600"
    />

    <div class="overlay">
        <div class="audit-form-card">
            <div class="form-header">
              <h2 v-if="!audit_data.AuditAbnormality">Submit Audit</h2>
              <h2 v-else>Submitted Audit</h2>
              <button class="close-button" @click="closeOverlay">
                <i class="fas fa-times"></i>
              </button>
            </div>
  
            <!-- Add image container here -->
            <div class="image-container">
                <div class="image-wrapper">
                  <img :src="audit_data.SignedUrl" alt="Audit Image" class="audit-image" @click="openImageViewer">
                  <button class="zoom-button" @click="openImageViewer">
                    <i class="fas fa-search-plus"></i>
                  </button>
                </div>
            </div>

            <div class="form-content" v-if="!audit_data.AuditAbnormality">
                <div class="form-group">
                    <label>Abnormality Status <span class="required">*</span></label>
                    <select 
                        v-model="formData.audit_abnormality" 
                        class="form-input"
                        :class="{ 'error': validationErrors.audit_abnormality }"
                    >
                        <option value="">Select Status</option>
                        <option value="normal">Normal</option>
                        <option value="abnormal">Abnormal</option>
                        <option value="non retinal image">Non Retinal Image</option>
                    </select>
                    <span v-if="validationErrors.audit_abnormality" class="error-message">
                        Please select abnormality status
                    </span>
                </div>

                <div class="form-group" v-if="formData.audit_abnormality === 'abnormal'">
                    <label>Audit Grade <span class="required">*</span></label>
                    <select 
                        v-model="formData.audit_grade" 
                        class="form-input"
                        :class="{ 'error': validationErrors.audit_grade }"
                    >
                        <option value="">Select Grade</option>
                        <option value="grade_1">Grade 1</option>
                        <option value="grade_2">Grade 2</option>
                    </select>
                    <span v-if="validationErrors.audit_grade" class="error-message">
                        Please select an audit grade
                    </span>
                </div>

                <div class="form-group">
                    <label>Audit Comments</label>
                    <textarea 
                    v-model="formData.audit_comment" 
                    class="form-input textarea"
                    placeholder="Enter your comments here..."
                    rows="4"
                    ></textarea>
                </div>
            
                <div class="form-actions">
                    <button class="submit-button" @click="handleSubmit">
                    Submit Audit
                    </button>
                </div>
            </div>

            <div class="form-content" v-else-if="audit_data.AuditAbnormality && isEditting">
                <div class="form-group">
                    <label>Abnormality Status <span class="required">*</span></label>
                    <select 
                        v-model="formData.audit_abnormality" 
                        class="form-input"
                        :class="{ 'error': validationErrors.audit_abnormality }"
                    >
                        <option value="">Select Status</option>
                        <option value="normal">Normal</option>
                        <option value="abnormal">Abnormal</option>
                        <option value="non retinal image">Non Retinal Image</option>
                    </select>
                    <span v-if="validationErrors.audit_abnormality" class="error-message">
                        Please select abnormality status
                    </span>
                </div>

                <div class="form-group" v-if="formData.audit_abnormality === 'abnormal'">
                    <label>Audit Grade <span class="required">*</span></label>
                    <select 
                        v-model="formData.audit_grade" 
                        class="form-input"
                        :class="{ 'error': validationErrors.audit_grade }"
                    >
                        <option value="">Select Grade</option>
                        <option value="grade_1">Grade 1</option>
                        <option value="grade_2">Grade 2</option>
                    </select>
                    <span v-if="validationErrors.audit_grade" class="error-message">
                        Please select an audit grade
                    </span>
                </div>

                <div class="form-group">
                    <label>Audit Comments</label>
                    <textarea 
                    v-model="formData.audit_comment" 
                    class="form-input textarea"
                    placeholder="Enter your comments here..."
                    rows="4"
                    ></textarea>
                </div>
            
                <div class="form-actions">
                    <button class="cancel-button mx-1" @click="isEditting = false">
                        Cancel
                    </button>
                    <button class="submit-button" @click="handleSubmit">
                        Submit Audit Edit
                    </button>
                </div>
            </div>

            <div class="form-content" v-else>
                <div class="form-group">
                    <label>Abnormality Status</label>
                    <div class="form-input">{{ audit_data.AuditAbnormality }}</div>
                </div>

                <div class="form-group">
                    <label>Audit Grade</label>
                    <div class="form-input">{{ audit_data.AuditGrade }}</div>
                </div>

                <div class="form-group" v-if="audit_data.AuditComments">
                    <label>Audit Comments</label>
                    <div class="form-input">{{ audit_data.AuditComments }}</div>
                </div>

                <div class="form-actions">
                    <button class="submit-button" @click="openEditAuditOverlay">
                        Edit Audit
                    </button>
                </div>
            </div>

        </div>
    </div>
  </template>
  
<script>
import { submitAudit } from '@/services/auditServices'
import ImageViewer from '@/components/ImageViewer.vue'

export default {
    components: {
        ImageViewer
    },

props: {
    audit_data: {
        type: Object,
        required: true
    }
},

data() {
    return {
    formData: {
        media_uuid: this.audit_data.MediaUuid,
        audit_grade: '',
        audit_abnormality: '',
        audit_comment: ''
    },
    validationErrors: {
        audit_grade: false,
        audit_abnormality: false
    },
    showImageViewer: false,
    isEditting: false
    }
},

methods: {
    validateForm() {
        this.validationErrors = {
            audit_grade: !this.formData.audit_grade,
            audit_abnormality: !this.formData.audit_abnormality
        }
        return !Object.values(this.validationErrors).some(error => error)
    },

    async handleSubmit() {

        switch(this.formData.audit_abnormality){
            case 'normal':
                this.formData.audit_grade = 'normal';
            break;
            case 'non retinal image':
                this.formData.audit_grade = 'non retinal image';
            break;
        }
        console.log(this.formData);

        if (!this.validateForm()) {
            return
        }
        try {
            await submitAudit(this.formData)
            this.$emit('audit-submitted')
            this.closeOverlay()
            this.resetFormData()
        } catch (error) {
            console.error('Error submitting audit:', error)
        }
    },

    openEditAuditOverlay() {
        this.isEditting = true
        this.formData.audit_grade = this.audit_data.AuditGrade
        this.formData.audit_abnormality = this.audit_data.AuditAbnormality
        this.formData.audit_comment = this.audit_data.AuditComments
    },

    resetFormData(){
        this.formData = {
            media_uuid: this.audit_data.MediaUuid,
            audit_grade: '',
            audit_abnormality: '',
            audit_comment: ''
        }
    },

    closeOverlay() {
      this.$emit('close')
    },

    openImageViewer() {
      this.showImageViewer = true
      console.log('image: ' + this.showImageViewer)
    },
}
}
</script>
  
<style scoped>
.required {
color: #ef4444;
margin-left: 4px;
}

.error {
border-color: #ef4444;
}

.error-message {
color: #ef4444;
font-size: 0.875rem;
margin-top: 0.25rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.75);
display: flex;
align-items: center;
justify-content: center;
z-index: 9999;
}

.image-container {
width: 100%;
margin-bottom: 1.5rem;
display: flex;
justify-content: center;
align-items: center;
}

.audit-image {
max-width: 100%;
max-height: 300px;
object-fit: contain;
border-radius: 8px;
}

.audit-form-card {
background: white;
border-radius: 12px;
width: 95%;
max-width: 600px;
padding: 1.5rem;
max-height: 90vh;
overflow-y: auto;
}

.form-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1.5rem;
}

.form-header h2 {
color: #1e293b;
font-size: 1.25rem;
font-weight: 600;
}

.close-button {
background: none;
border: none;
color: #64748b;
cursor: pointer;
font-size: 1.25rem;
}

.form-content {
display: flex;
flex-direction: column;
gap: 1.5rem;
}

.form-group {
display: flex;
flex-direction: column;
gap: 0.5rem;
}

.form-group label {
color: #475569;
font-weight: 500;
font-size: 0.875rem;
}

.form-input {
padding: 0.75rem;
border: 1px solid #e2e8f0;
border-radius: 6px;
font-size: 0.95rem;
transition: all 0.2s;
}

.form-input:focus {
outline: none;
border-color: #3b82f6;
box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.textarea {
resize: vertical;
min-height: 100px;
}

.form-actions {
margin-top: 2rem;
display: flex;
justify-content: flex-end;
}

.submit-button {
background: var(--primary-color);
color: white;
padding: 0.75rem 2rem;
border: none;
border-radius: 6px;
font-weight: 500;
cursor: pointer;
transition: all 0.2s;
}

.submit-button:hover {
background: var(--primary-hover);
transform: translateY(-1px);
}

.cancel-button {
background: #dc362e;
color: white;
padding: 0.75rem 2rem;
border: none;
border-radius: 6px;
font-weight: 500;
cursor: pointer;
transition: all 0.2s;
}

.cancel-button:hover {
background: #410e0b;
transform: translateY(-1px);
}
</style>
  
<style scoped>
.image-wrapper {
  position: relative;
  display: inline-block;
}

.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
}

.zoom-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.audit-image {
  cursor: pointer;
}
</style>