import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

export const getAuditCase = async (start_date, end_date, isAudited, withImage, page, limit, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/cases`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                start_date: start_date.toISOString().split('T')[0],
                end_date: end_date.toISOString().split('T')[0],
                isAudited,
                withImage,
                page,
                limit
            }
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return getAuditCase(start_date, end_date, isAudited, withImage, page, limit, true);
        }
        throw error;
    }
}

export const submitAudit = async (input_data, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.post(`${BACKEND_URL}/nora_superadmin/audit`, input_data, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return submitAudit(input_data, true);
        }
        throw error;
    }
}