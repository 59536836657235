<template>

  <div class="container mt-4">

    <div class="org-select-container mb-4" v-if="dashboard_data?.organization">
      <div class="org-select-header d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Select Organization</h6>
        <select 
          class="form-select org-select" 
          v-model="org_selected"
          @change="handleDateChange"
        >
          <option value="ALL">All Organizations</option>
          <option 
            v-for="org in dashboard_data?.organization" 
            :key="org.uuid"
            :value="org.uuid"
          >
            {{ org.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="date-filter-container">
      <div class="date-filter-header">
        <h6 class="mb-3 mb-md-0">Select Date Range</h6>
        <div class="preset-buttons d-flex gap-2">
          <button 
            class="btn btn-sm btn-outline-secondary"
            @click="setLastWeek"
          >
            <i class="fas fa-calendar-week d-inline d-md-none"></i>
            <span class="d-none d-md-inline">Last 7 Days</span>
          </button>
          <button 
            class="btn btn-sm btn-outline-secondary"
            @click="setLastMonth"
          >
            <i class="fas fa-calendar-alt d-inline d-md-none"></i>
            <span class="d-none d-md-inline">Last 30 Days</span>
          </button>
        </div>
      </div>
      
      <div class="date-inputs flex-column flex-md-row">
        <div class="input-group mb-2 mb-md-0">
          <span class="input-group-text">
            <i class="fas fa-calendar-alt"></i>
          </span>
          <input 
            type="date" 
            class="form-control"
            v-model="startDate"
            @change="handleDateChange"
          >
        </div>
        <div class="date-separator my-1 my-md-0 text-center">to</div>
        <div class="input-group">
          <span class="input-group-text">
            <i class="fas fa-calendar-alt"></i>
          </span>
          <input 
            type="date" 
            class="form-control"
            v-model="endDate"
            @change="handleDateChange"
            :min="startDate"
          >
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid container py-4" v-if="dashboard_data">

    <!-- Stats Cards Row -->
    <div class="row g-4 mb-4">
      <div class="col-12 col-md-6" v-if="dashboard_data.case_statistics.total_cases ">
        <div class="stat-card">
          <div class="stat-icon">
            <div class="icon-wrapper primary">
              <i class="fas fa-folder-open"></i>
            </div>
          </div>
          <div class="stat-content">
            <h3 class="stat-value">{{ dashboard_data.case_statistics.total_cases }}</h3>
            <p class="stat-label">Total Images</p>
            <div class="stat-trend">
              <i class="fas fa-chart-line"></i>
              <span>All time</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6" v-if="dashboard_data.case_statistics.total_abnormal_cases">
        <div class="stat-card" @click="showAbnormalBelow()">
          <div class="stat-icon">
            <div class="icon-wrapper danger">
              <i class="fas fa-exclamation-circle"></i>
            </div>
          </div>
          <div class="stat-content">
            <h3 class="stat-value">{{ dashboard_data.case_statistics.total_abnormal_cases }}</h3>
            <p class="stat-label">Abnormal Images</p>
            <div class="stat-trend danger">
              <i class="fas fa-exclamation-triangle"></i>
              <span>Requires attention</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6" v-if="dashboard_data.new_patients.length">
        <div class="stat-card">
          <div class="stat-icon">
            <div class="icon-wrapper success">
              <i class="fas fa-users"></i>
            </div>
          </div>
          <div class="stat-content">
            <h3 class="stat-value">{{ dashboard_data.new_patients.length }}</h3>
            <p class="stat-label">Total New Patients Registered</p>
            <div class="stat-trend">
              <i class="fas fa-user-plus"></i>
              <span>Active patients</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6" v-if="dashboard_data.case_statistics.total_abnormal_patients">
        <div class="stat-card" @click="showAbnormalBelow()">
          <div class="stat-icon">
            <div class="icon-wrapper danger">
              <i class="fas fa-exclamation-circle"></i>
            </div>
          </div>
          <div class="stat-content">
            <h3 class="stat-value">{{ dashboard_data.case_statistics.total_abnormal_patients }}</h3>
            <p class="stat-label">Abnormal Patients</p>
            <div class="stat-trend danger">
              <i class="fas fa-exclamation-triangle"></i>
              <span>Requires attention</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6" v-if="dashboard_data.case_statistics.average_image_quality">
        <div class="stat-card">
          <div class="stat-icon">
            <div class="icon-wrapper" :class="imageQualityClass">
              <i class="fas fa-camera-retro"></i>
            </div>
          </div>
          <div class="stat-content">
            <h3 class="stat-value">
              {{ (dashboard_data.case_statistics.average_image_quality).toFixed(2) }}
              <i v-if="dashboard_data.case_statistics.average_image_quality >= 0.9" 
                 class="fas fa-star text-warning ms-2"></i>
            </h3>
            <p class="stat-label">Average Image Quality</p>
            <div class="stat-trend" :class="imageQualityClass">
              <i class="fas fa-chart-line"></i>
              <span>{{ imageQualityLabel }}</span>
            </div>
          </div>
        </div>
      </div>
      

    </div>

    <!-- Charts Row -->
    <div class="row g-4 mb-4">
      <div class="col-12 col-lg-6">
        <div class="chart-card">
          <div class="chart-header">
            <h5>Case Grading Distribution</h5>
            <div class="chart-legend">
              <span class="legend-item severe"><i class="fas fa-circle"></i>Grade 2</span>
              <span class="legend-item moderate"><i class="fas fa-circle"></i>Grade 1</span>
              <span class="legend-item normal"><i class="fas fa-circle"></i>Normal</span>
            </div>
          </div>
          <div class="chart-body">
            <Pie :data="gradingByCaseData" :options="chartOptions" />
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="chart-card">
          <div class="chart-header">
            <h5>Patient Grading Distribution</h5>
            <div class="chart-legend">
              <span class="legend-item severe"><i class="fas fa-circle"></i>Grade 2</span>
              <span class="legend-item moderate"><i class="fas fa-circle"></i>Grade 1</span>
              <span class="legend-item normal"><i class="fas fa-circle"></i>Normal</span>
            </div>
          </div>
          <div class="chart-body">
            <Pie :data="gradingByPatientData" :options="chartOptions" />
          </div>
        </div>
      </div>
    </div>

    <!-- Timeline Chart -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="chart-card">
          <div class="chart-header">
            <h5>Patient Registration Timeline</h5>
            <!-- <div class="timeline-filters">
              <span class="active">Daily</span>
              <span>Weekly</span>
              <span>Monthly</span>
            </div> -->
          </div>
          <div class="chart-body">
            <Line :data="registrationTimelineData" :options="lineChartOptions" />
          </div>
        </div>
      </div>
    </div>

    <!-- Age Group Distribution Chart -->
    <div class="row mb-4" v-if="ageGroupData">
      <div class="col-12">
        <div class="chart-card">
          <div class="chart-header">
            <h5>Age Group Distribution</h5>
            <div class="chart-legend">
              <span class="legend-item severe"><i class="fas fa-circle"></i>Grade 2</span>
              <span class="legend-item moderate"><i class="fas fa-circle"></i>Grade 1</span>
              <span class="legend-item normal"><i class="fas fa-circle"></i>Normal</span>
            </div>
          </div>
          <div class="chart-body">
            <Bar :data="ageGroupData" :options="barChartOptions" />
          </div>
        </div>
      </div>
    </div>


    <!-- Patients Table -->
    <div class="card border-0 shadow-sm">

      <div class="card-header bg-white d-flex justify-content-between align-items-center py-3" id="recent-patients-table">
        <h5 class="card-title mb-0">Recent Patients</h5>
        <div class="d-flex align-items-center gap-2">
          <div class="btn-group">
            <button 
              class="btn btn-sm" 
              :class="currentFilter === 'all' ? 'btn-primary' : 'btn-outline-primary'"
              @click="setFilter('all')"
            >
              All
            </button>
            <button 
              class="btn btn-sm" 
              :class="currentFilter === 'abnormal' ? 'btn-primary' : 'btn-outline-primary'"
              @click="setFilter('abnormal')"
            >
              Abnormal Only
            </button>
          </div>
          <button class="btn btn-primary btn-sm" @click="$router.push('/patients')">View All</button>
        </div>
      </div>


      <div class="table-responsive">
        <table class="table table-hover">
          <thead class="table-light">
            <tr>
              <th>Patient ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Age Group</th>
              <th class="text-center">Highest Grade</th>
              <!-- <th>Status</th> -->
            </tr>
          </thead>

          <tbody>
            <tr v-for="patient in filteredPatients" :key="patient.PatientUuid">
              <td>{{ patient.PatientIdentifier? patient.PatientIdentifier.toUpperCase() : '' }}</td>
              <td>{{ patient.PatientName.toUpperCase() }}</td>    
              <td>{{ patient.PatientAgeAtScreening || 'N/A' }}</td>
              <td>
                <span class="badge bg-info">{{ patient.age_group }}</span>
              </td>
              <td v-if="patient.highest_grading" class="text-center">
                <span 
                  class="badge"
                  :class="{
                    'bg-success': patient.highest_grading === 'Normal',
                    'bg-warning': patient.highest_grading === 'Grade_1',
                    'bg-danger': patient.highest_grading === 'Grade_2'
                  }"
                >
                  {{ patient.highest_grading.replace('_', ' ') }}
                </span>
              </td>
              <td>
                <!-- <router-link :to="`case/patient/${patient.PatientUuid}`" class="badge btn-primary text-decoration-none">
                  View Profile
                </router-link> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement } from 'chart.js'
  import { Pie, Line, Bar } from 'vue-chartjs'
  import { getDashboardbyDate } from '@/services/dashboardServices'

  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement)
  
  export default {
    name: 'Dashboard',
    components: { Pie, Line, Bar },
  
    data() {
      return {
        dashboard_data: null,
        period: 'month',
        startDate: '',
        endDate: '',
        org_selected: 'ALL',
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false // Hide default legend
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#2c3e50',
              bodyColor: '#2c3e50',
              borderColor: '#e9ecef',
              borderWidth: 1,
              padding: 12,
              boxPadding: 6,
              usePointStyle: true
            }
          },
          cutout: '65%' // Makes donut chart
        },
        lineChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#2c3e50',
              bodyColor: '#2c3e50',
              borderColor: '#e9ecef',
              borderWidth: 1,
              padding: 12
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: '#f0f0f0',
                drawBorder: false
              },
              ticks: {
                stepSize: 1,
                padding: 10
              }
            },
            x: {
              grid: {
                display: false
              },
              ticks: {
                padding: 10
              }
            }
          }
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#2c3e50',
              bodyColor: '#2c3e50',
              borderColor: '#e9ecef',
              borderWidth: 1,
              padding: 12
            }
          },
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                padding: 10
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: '#f0f0f0',
                drawBorder: false
              },
              ticks: {
                padding: 10
              }
            }
          }
        },
        currentFilter: 'all',
      }
    },
  
    computed: {
      imageQualityClass() {
        const quality = this.dashboard_data?.case_statistics.average_image_quality;
        if (quality >= 0.9) return 'success';
        if (quality >= 0.6) return 'success';
        if (quality >= 0.3) return 'warning';
        return 'danger';
      },
      
      imageQualityLabel() {
        const quality = this.dashboard_data?.case_statistics.average_image_quality;
        if (quality >= 0.9) return 'Very Good';
        if (quality >= 0.6) return 'Good';
        if (quality >= 0.3) return 'Moderate';
        return 'Bad';
      },

      gradingByCaseData() {
        if (!this.dashboard_data) return null
        const data = this.dashboard_data.case_statistics.grading_counts_by_case
        return {
          labels: ['Grade 2', 'Grade 1', 'Grade 0 (Normal)'],
          datasets: [{
            data: [
              data.Grade_2, 
              data.Grade_1, 
              data.normal // Combine Grade 0 and Normal
            ],
            backgroundColor: ['#dc3545', '#ffc107', '#3989b8']
          }]
        }
      },
  
      gradingByPatientData() {
        if (!this.dashboard_data) return null
        const data = this.dashboard_data.case_statistics.grading_counts_by_patient
        return {
          labels: ['Grade 2', 'Grade 1', 'Grade 0 (Normal)'],
          datasets: [{
            data: [
              data.Grade_2, 
              data.Grade_1, 
              data.normal // Combine Grade 0 and Normal
            ],
            backgroundColor: ['#dc3545', '#ffc107', '#3989b8']
          }]
        }
      },
  
      registrationTimelineData() {
        if (!this.dashboard_data) return null
        const data = this.dashboard_data.patient_registration_counts
        return {
          labels: data.map(item => new Date(item.date).toLocaleDateString()),
          datasets: [{
            label: 'New Registrations',
            data: data.map(item => item.count),
            borderColor: '#3989b8',
            backgroundColor: 'rgba(15, 186, 178, 0.1)',
            tension: 0.4,
            fill: true
          }]
        }
      },

      ageGroupData() {
        if (!this.dashboard_data?.patient_age_group_counts) return null;
        
        const ageGroups = Object.keys(this.dashboard_data.patient_age_group_counts);
        
        return {
          labels: ageGroups,
          datasets: [
            {
              label: 'Normal',
              data: ageGroups.map(group => this.dashboard_data.patient_age_group_counts[group].normal),
              backgroundColor: '#3989b8',
              borderRadius: 6,
            },
            {
              label: 'Grade 1',
              data: ageGroups.map(group => this.dashboard_data.patient_age_group_counts[group].Grade_1),
              backgroundColor: '#ffc107',
              borderRadius: 6,
            },
            {
              label: 'Grade 2',
              data: ageGroups.map(group => this.dashboard_data.patient_age_group_counts[group].Grade_2),
              backgroundColor: '#dc3545',
              borderRadius: 6,
            }
          ]
        }
      },

      filteredPatients() {
        if (!this.dashboard_data?.new_patients) return [];
        
        let patients = [...this.dashboard_data.new_patients];
        
        // Sort by grade priority (Grade_2 > Grade_1 > Normal)
        patients.sort((a, b) => {
          const gradeOrder = { 'Grade_2': 2, 'Grade_1': 1, 'Normal': 0 };
          return gradeOrder[b.highest_grading] - gradeOrder[a.highest_grading];
        });
        
        // Filter abnormal cases if selected
        if (this.currentFilter === 'abnormal') {
          patients = patients.filter(patient => 
            patient.highest_grading === 'Grade_2' || patient.highest_grading === 'Grade_1'
          );
        }
        
        return patients;
      }

    },
  
    mounted() {
      this.setLastMonth();
    },

    methods:{
      selectOrganization(uuid) {
        this.org_selected = uuid;
        this.handleDateChange(); // This will trigger data refresh
      },
      handleDateChange() {
        if (this.startDate && this.endDate) {
          this.getDashboardData();
        }
      },

      async getDashboardData() {
          try {
              this.dashboard_data = await getDashboardbyDate(this.startDate, this.endDate, this.org_selected);
              console.log('Dashboard data:', this.dashboard_data);
          } catch (error) {
              console.error('Error fetching dashboard data:', error);
          }
      },

      setLastWeek() {
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - 7);
        
        this.endDate = end.toISOString().split('T')[0];
        this.startDate = start.toISOString().split('T')[0];
        this.handleDateChange();
      },

      setLastMonth() {
        console.log('setLastMonth called');
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - 30);
        
        this.endDate = end.toISOString().split('T')[0];
        this.startDate = start.toISOString().split('T')[0];
        this.handleDateChange();
      },

      setFilter(filter) {
        this.currentFilter = filter;
      },

      showAbnormalBelow(){
        this.setFilter('abnormal');
        document.getElementById('recent-patients-table').scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }
</script>
  
<style scoped>
.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-3px);
}

.table th {
  font-weight: 600;
}

.table td {
  vertical-align: middle;
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 1rem;
  }
}
</style>

<style scoped>
/* ... existing styles ... */

.date-filter-container {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.date-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-separator {
  color: #6c757d;
  font-weight: 500;
}

.input-group {
  flex: 1;
}

.input-group-text {
  background-color: #f8f9fa;
  border-right: none;
}

.form-control {
  border-left: none;
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.preset-buttons .btn {
  transition: all 0.2s;
}

.preset-buttons .btn:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}
</style>

<style scoped>
.date-filter-container {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.date-filter-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .date-filter-container {
    padding: 1.5rem;
  }
  
  .date-filter-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.input-group {
  flex: 1;
}

.date-separator {
  color: #6c757d;
  font-weight: 500;
  width: 100%;
}

@media (min-width: 768px) {
  .date-separator {
    width: auto;
  }
}

.input-group-text {
  background-color: #f8f9fa;
  border-right: none;
}

.form-control {
  border-left: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.preset-buttons .btn {
  transition: all 0.2s;
  min-width: 40px;
}

@media (min-width: 768px) {
  .preset-buttons .btn {
    min-width: 100px;
  }
}

.preset-buttons .btn:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color)2;
  color: white;
}
</style>

<style scoped>
.stat-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(0,0,0,0.1);
}

.icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.icon-wrapper.primary {
  background: rgba(57, 137, 184, 0.1);
  color: #3989b8;
}

.icon-wrapper.danger {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.icon-wrapper.success {
  background: rgba(25, 135, 84, 0.1);
  color: #198754;
}

.stat-content {
  flex: 1;
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
  color: #2c3e50;
}

.stat-label {
  color: #6c757d;
  margin: 0.25rem 0 0.75rem;
  font-size: 0.9rem;
}

.stat-trend {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.35rem 0.75rem;
  background: rgba(57, 137, 184, 0.1);
  border-radius: 20px;
  font-size: 0.8rem;
  color: #3989b8;
}

.stat-trend.danger {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.icon-wrapper.warning {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.stat-trend.warning {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

@media (max-width: 768px) {
  .stat-card {
    padding: 1.25rem;
  }
  
  .stat-value {
    font-size: 1.5rem;
  }
}
</style>

<style scoped>
.chart-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  height: 100%;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.chart-header h5 {
  font-size: 1.1rem;
  color: #2c3e50;
  margin: 0;
}

.chart-legend {
  display: flex;
  gap: 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  color: #6c757d;
}

.legend-item i {
  font-size: 0.6rem;
}

.legend-item.severe i { color: #dc3545; }
.legend-item.moderate i { color: #ffc107; }
.legend-item.normal i { color: #3989b8; }

.chart-body {
  height: 300px;
  position: relative;
}

.timeline-filters {
  display: flex;
  gap: 0.5rem;
}

.timeline-filters span {
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  color: #6c757d;
  cursor: pointer;
  transition: all 0.2s;
}

.timeline-filters span.active {
  background: #3989b8;
  color: white;
}

@media (max-width: 768px) {
  .chart-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .chart-legend {
    flex-wrap: wrap;
  }
}
</style>

<style>

.btn-primary{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover{
  background-color: var(--primary-hover) !important;
  border-color: var(--primary-hover) !important;
}

.btn-outline-primary{
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bg-primary{
  background-color: var(--primary-color) !important;
}

</style>

<style scoped>
.org-select-container {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.org-select {
  max-width: 300px;
  border-radius: 8px;
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  border-color: #e9ecef;
  cursor: pointer;
  transition: all 0.2s ease;
}

.org-select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.25rem rgba(57, 137, 184, 0.25);
}

@media (max-width: 768px) {
  .org-select-header {
    flex-direction: column;
    gap: 1rem;
  }
  
  .org-select {
    max-width: 100%;
  }
}
</style>
