<script>
import { getAllUser, getUserUuid } from '@/services/userServices'
import { getIntegration, getAllIntegration, deactivateIntegration } from '@/services/integrationServices'
import IntegrationRegister from './components/IntegrationRegister.vue'
import { KeyIcon } from 'lucide-vue-next'

export default {
  components: {
    IntegrationRegister,
    KeyIcon
  },
  data() {
    return {
      loading: false,
      search: '',
      selectedUser: null,
      users: [],
      isRegister: false,
      integrationKeys: null,
      showNoResults: false,
      visibleKeys: new Set(),
      allOrg: null,
      statusFilter: 'active', // Add this line - possible values: 'all', 'active', 'inactive'
      sortField: null,
      sortDirection: 'desc'
    }
  },
  computed: {
    filteredUsers() {
      if (this.search.length < 2) return [];
      return this.users.filter(user =>
        user.full_name.toLowerCase().includes(this.search.toLowerCase()) ||
        user.clinic_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    filteredIntegrationKeys() {
      if (!this.integrationKeys) return null;
      let keys = this.integrationKeys;

      // Filter by search term
      if (this.search.length >= 2) {
        keys = keys.filter(key =>
          key.OrganizationName.toLowerCase().includes(this.search.toLowerCase()) ||
          key.UserName.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      // Filter by status
      if (this.statusFilter !== 'all') {
        keys = keys.filter(key =>
          this.statusFilter === 'active' ? key.IsActive : !key.IsActive
        );
      }

      return keys;
    },
    sortedIntegrationKeys() {
      if (!this.filteredIntegrationKeys) return null;

      return [...this.filteredIntegrationKeys].sort((a, b) => {
        if (this.sortField === 'lastUsed') {
          const dateA = a.LastSentDate ? new Date(a.LastSentDate) : new Date(0);
          const dateB = b.LastSentDate ? new Date(b.LastSentDate) : new Date(0);
          return this.sortDirection === 'desc' ? dateB - dateA : dateA - dateB;
        }
        return 0;
      });
    }
  },
  methods: {
    toggleSort(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'desc';
      }
    },

    isKeyVisible(keyId) {
      return this.visibleKeys.has(keyId)
    },
    
    toggleKeyVisibility(keyId) {
      if (this.visibleKeys.has(keyId)) {
        this.visibleKeys.delete(keyId)
      } else {
        this.visibleKeys.add(keyId)
      }
    },

    async fetchUsers() {
      try {
        this.loading = true
        const response = await getAllUser()
        const res2 = await getUserUuid()
        console.log(res2)
        console.log(response)
        this.users = response.users
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        this.loading = false
      }
    },

    async fetchIntegrationKeys() {
      try {
        this.loading = true

        let response = null;

        if (!this.selectedUser) {
          console.log(this.selectedUser)
          response = await getAllIntegration()
        }
        else{
          console.log(this.selectedUser)
          response = await getIntegration(this.selectedUser.uuid, this.selectedUser.OrganizationUuid)
        }
        
        if (response.data && response.data.length) {
          this.integrationKeys = response.data
          this.showNoResults = false
        } else {
          this.integrationKeys = null
          this.showNoResults = true
        }
      } catch (error) {
        console.error('Error fetching integration keys:', error)
        this.integrationKeys = null
        this.showNoResults = true
      } finally {
        this.loading = false
      }
    },
     
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
    },

    convertToMalaysiaTime(utcDateTime) {
        // Parse the UTC datetime string
        const utcDate = new Date(utcDateTime + 'Z'); // Add 'Z' to indicate UTC
        // Malaysia Time is UTC +8
        const malaysiaTimeOffset = 8 * 60; // Offset in minutes
        // Create a new Date object with the Malaysia Time offset
        const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset * 60 * 1000);
        // Format the date to a readable string
        return malaysiaDate.toISOString().replace('T', ' ').slice(0, 19);
    },

    formatDate(dateString) {
      const convertedDate = this.convertToMalaysiaTime(dateString);
      return new Date(convertedDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    selectUser(user) {
        if(user){
          this.selectedUser = user
          this.search = user.full_name + ' '
        }
        else{
          this.selectedUser = null
        }
        this.fetchIntegrationKeys()
      },
    async deactivateKey(keyID){
      const response_deactivate = await deactivateIntegration(keyID);
      if(response_deactivate.status == 'success'){
        this.fetchIntegrationKeys()
      }
    }

  },
  mounted() {
    this.fetchUsers()
    this.fetchIntegrationKeys()
  },
}
</script>

<template>

  <div class="text-center mt-4">
    <h3><KeyIcon /> Integrations</h3>
  </div>
    <div class="audit-container">
      <div class="audit-card">
        <!-- Search and Controls Section -->
        <div class="controls-wrapper">
          <div class="search-section">
            <input 
              v-model="search"
              type="text"
              placeholder="Search by name or clinic..."
              class="form-control custom-search"
            />
            <!-- existing search results -->
          </div>
          
          <div class="controls-right">

            <div class="sort-controls">
              <label>Sort by Last Used:</label>
              <button 
                @click="toggleSort('lastUsed')" 
                class="btn sort-toggle"
              >
                {{ sortDirection === 'desc' ? 'Newest First' : 'Oldest First' }}
                <i :class="['fas', sortDirection === 'desc' ? 'fa-sort-down' : 'fa-sort-up']"></i>
              </button>
            </div>

            <div class="filter-section">
              <select v-model="statusFilter" class="form-select custom-select">
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <button 
              @click="isRegister = true"
              class="btn custom-btn-primary"
            >
              Generate New Key
            </button>
          </div>
        </div>
        

        <!-- Integration Keys Table -->
        <div class="table-responsive custom-table-container">
          <div v-if="loading" class="text-center p-4">Loading...</div>
          
          <div v-else-if="showNoResults" class="text-center p-4">
            No integration keys found
          </div>

          <div v-else>

            <!-- Desktop Table -->
            <table class="table custom-table d-none d-md-table">
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>User</th>
                  <th>Integration Key</th>
                  <th>Created Date</th>
                  <th @click="toggleSort('lastUsed')" class="sortable-header">
                    Last Used
                    <i v-if="sortField === 'lastUsed'" 
                      :class="['fas', sortDirection === 'desc' ? 'fa-sort-down' : 'fa-sort-up']">
                    </i>
                  </th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="key in sortedIntegrationKeys" :key="key.IntegrationKeyID">
                  <td>{{ key.OrganizationName }}</td>
                  <td>{{ key.UserName }}</td>
                  <td class="key-cell">
                    <div class="d-flex align-items-center">
                      <code class="integration-key">
                        {{ isKeyVisible(key.IntegrationKeyID) ? key.IntegrationKey : '••••••••••••••••' }}
                      </code>
                      <button 
                        @click="toggleKeyVisibility(key.IntegrationKeyID)"
                        class="btn btn-icon"
                      >
                          <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button 
                        @click="copyToClipboard(key.IntegrationKey)"
                        class="btn btn-icon ms-2"
                      >
                          <i class="fa fa-clipboard" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ formatDate(key.CreatedAt) }}</td>
                  <td>{{ key.LastSentDate ? formatDate(key.LastSentDate) : '-' }}</td>
                  <td>
                    <span :class="['status-badge', key.IsActive ? 'active' : 'inactive']">
                      {{ key.IsActive ? 'Active' : 'Inactive' }}
                    </span>
                  </td>
                  <td>
                    <button 
                      v-if="key.IsActive"
                      @click="deactivateKey(key.IntegrationKeyID)"
                      class="btn custom-btn-danger"
                    >
                      Deactivate
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <!-- Mobile View -->
            <div class="d-md-none">
              <div v-for="key in sortedIntegrationKeys" 
                  :key="key.IntegrationKeyID" 
                  class="mobile-integration-card">
                <div class="card-header">
                  <span class="org-name">{{ key.OrganizationName }}</span>
                  <span :class="['status-badge', key.IsActive ? 'active' : 'inactive']">
                    {{ key.IsActive ? 'Active' : 'Inactive' }}
                  </span>
                </div>
                <div class="card-body">
                  <div class="info-item">
                    <span class="label">User:</span>
                    <span>{{ key.UserName }}</span>
                  </div>
                  <div class="info-item">
                    <span class="label">Integration Key:</span>
                    <div class="key-actions">
                      <code class="integration-key">
                        {{ isKeyVisible(key.IntegrationKeyID) ? key.IntegrationKey : '••••••••••••••••' }}
                      </code>
                      <div class="action-buttons">
                        <button @click="toggleKeyVisibility(key.IntegrationKeyID)" class="btn btn-icon">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button @click="copyToClipboard(key.IntegrationKey)" class="btn btn-icon">
                          <i class="fa fa-clipboard" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="info-item">
                    <span class="label">Created:</span>
                    <span>{{ formatDate(key.CreatedAt) }}</span>
                  </div>
                  <div class="info-item">
                    <span class="label">Last Used:</span>
                    <span>{{ key.LastSentDate ? formatDate(key.LastSentDate) : '-' }}</span>
                  </div>
                  <div class="action-buttons mt-3">
                    <button v-if="key.IsActive"
                            @click="deactivateKey(key.IntegrationKeyID)"
                            class="btn custom-btn-danger w-100">
                      Deactivate
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="sortedIntegrationKeys == 0 && search" class="no-results">
                <i class="fas fa-search"></i>
                <p>No results found for "{{ search }}"</p>
            </div>

          </div>

        </div>

        <!-- Registration Modal -->
        <IntegrationRegister 
          :show="isRegister"
          @close="isRegister = false"
          @refresh="fetchIntegrationKeys"
        />
      </div>
    </div>
</template>

<style scoped>
.integration-container {
  padding: 16px;
}

.controls-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .integration-container {
    padding: 24px;
  }

  .controls-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.search-section {
  width: 100%;
}

@media (min-width: 768px) {
  .search-section {
    width: 400px;
  }
}

.mobile-card {
  background: white;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-header {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body {
  padding: 16px;
}

.info-row {
  margin-bottom: 12px;
}

.label {
  font-weight: 600;
  margin-right: 8px;
  color: #666;
}

.key-wrapper {
  margin-top: 8px;
  background: #f8f9fa;
  padding: 8px;
  border-radius: 6px;
  word-break: break-all;
}

.action-row {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.integration-container {
  padding: 24px;
}

.custom-search {
  width: 400px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px 16px;
}

.search-results {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-item {
  padding: 10px 16px;
  cursor: pointer;
}

.search-item:hover {
  background-color: #f8f9fa;
}

.custom-table-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.custom-table {
  margin-bottom: 0;
}

.custom-table thead th {
  background: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
  padding: 16px;
  font-weight: 600;
}

.custom-table tbody td {
  padding: 16px;
  vertical-align: middle;
}

.integration-key {
  background: #f8f9fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-btn-primary {
  background: #4a90e2;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 8px;
}

.custom-btn-danger {
  background: #dc3545;
  border: none;
  padding: 8px 16px;
  color: white;
  border-radius: 6px;
  font-size: 0.9em;
}

.btn-icon {
  padding: 6px;
  background: transparent;
  border: none;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9em;
}

.status-badge.active {
  background: #e8f5e9;
  color: #2e7d32;
}

.status-badge.inactive {
  background: #ffebee;
  color: #c62828;
}

.filter-section {
  margin: 0 16px;
}

.custom-select {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px 16px;
  min-width: 150px;
}

@media (max-width: 768px) {
  .filter-section {
    margin: 8px 0;
  }
}
</style>
<style scoped>
.controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.controls-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.custom-select {
  height: 42px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 16px;
  min-width: 150px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.custom-select:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

@media (max-width: 768px) {
  .controls-wrapper {
    flex-direction: column;
  }
  
  .controls-right {
    width: 100%;
    justify-content: space-between;
  }
  
  .filter-section {
    flex: 1;
    margin-right: 16px;
  }
}

.sortable-header {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.sortable-header:hover {
  background-color: #f0f0f0;
}

.sortable-header i {
  margin-left: 8px;
  font-size: 14px;
}
</style>

<style scoped>

.no-results {
  text-align: center;
  padding: 3rem;
  color: #64748b;
  font-size: 1.1rem;
}

/* Add these new mobile-friendly styles */
.mobile-integration-card {
  background: white;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-integration-card .card-header {
  padding: 12px 16px;
  background: #f8f9fa;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-integration-card .card-body {
  padding: 16px;
}

.mobile-integration-card .info-item {
  margin-bottom: 12px;
}

.mobile-integration-card .label {
  font-weight: 600;
  color: #666;
  display: block;
  margin-bottom: 4px;
}

.mobile-integration-card .key-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-integration-card .integration-key {
  font-size: 12px;
  word-break: break-all;
  background: #f8f9fa;
  padding: 8px;
  border-radius: 4px;
  display: block;
}

.mobile-integration-card .action-buttons {
  display: flex;
  gap: 8px;
}

/* Improve existing responsive controls */
@media (max-width: 768px) {
  .custom-search {
    width: 100%;
  }
  
  .controls-wrapper {
    gap: 12px;
  }
  
  .controls-right {
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .custom-btn-primary {
    width: 100%;
  }
  
  .filter-section {
    width: 100%;
  }
  
  .custom-select {
    width: 100%;
  }
}
</style>

<style scoped>
.sort-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.sort-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.sort-toggle:hover {
  background: #f8f9fa;
  border-color: #4a90e2;
}

.sort-toggle i {
  font-size: 12px;
}
</style>

<style scoped>

.audit-container {
  padding: 1rem;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .audit-container {
    padding: 2rem;
  }
}

.audit-container {
  padding: 2rem;
  min-height: 100vh;
}

.audit-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  padding: 1.5rem;
  margin: 0 auto;
}

.audit-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

</style>