
<template>

    <!-- LOOPING TABLE CASES -->
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <div class="text-center mt-4">
        <h3><BuildingIcon /> Organization</h3>
    </div>

    <!-- ================ SYMPTOM EDITOR TABLE ================ -->
    <div class="container platform-size my-1">

      <SearchBar
      searchPlaceholder="Search Organization Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      class="mb-3"
      />

      <div class="card round-card">
          <div class="card-body">

            <div class="flex-md ">
              <div class="row mx-1 mb-2">
                <button class="btn" style="background-color: var(--primary-color); color: white; padding:5px;" @click="$router.push('/org/register')"><i class="fa-solid fa-plus"></i> Add Organization</button>
              </div>
            </div>

            <Table :input_object="render_data">
              <template v-slot="{ data }">
                <div class="org-card p-3 mb-3 border rounded hover-shadow">
                  <div class="row">
                    <!-- Organization Name and Contact -->
                    <div class="col-md-4">
                      <div class="org-header">
                        <div class="org-icon">
                          <i class="fas fa-building"></i>
                        </div>
                        <div class="org-main-info">
                          <h5 class="org-name mb-1 text-truncate" :title="data.OrganizationName">
                            {{ data.OrganizationName }}
                          </h5>
                          <div class="contact-info text-truncate" :title="data.ContactEmail">
                            <i class="fas fa-envelope text-muted me-2"></i>
                            <span>{{ data.ContactEmail }}</span>
                          </div>
                          <div class="contact-info text-truncate" :title="data.ContactPhone">
                            <i class="fas fa-phone text-muted me-2"></i>
                            <span>{{ data.ContactPhone }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    <!-- Location Details -->
                    <div class="col-md-5">
                      <div class="location-details">
                        <div class="detail-row">
                          <i class="fas fa-map-marked-alt text-primary me-2"></i>
                          <span>{{ data.HQAddress }}</span>
                        </div>
                        <div class="detail-row">
                          <i class="fas fa-location-dot text-primary me-2"></i>
                          <span>{{ data.City }}, {{ data.State }} {{ data.PostalCode }}</span>
                        </div>
                      </div>
                    </div>
        
                    <!-- Action Buttons -->
                    <div class="col-md-3">
                      <div class="action-buttons text-end">
                        <button class="btn btn-primary btn-sm action-btn" @click="goto_editor(data.OrganizationUuid)">
                          <i class="fa-regular fa-pen-to-square me-2"></i>
                          Manage Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Table>

          </div>

      </div>
    </div>

    





</template>

<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Table from "@/components/ListWithPaginate.vue";
import AddOrg from "@/pages/Organization/components/AddOrganization.vue"
import { getAllOrg } from "@/services/orgServices"
import {BuildingIcon} from 'lucide-vue-next'


export default{
components: {
Loading,
Modal,
SearchBar,
Table,
AddOrg,
BuildingIcon
},

created(){
this.get_data();
console.warn("Created DOM!");
},
data() {
    return {
    raw_data : [],
    render_data : [],
    current_page : 0,
    page_limit : 10,
    isAddOrg: false,
    isLoading: false,
    search_query: '',
    click_categories: false,
    modal :{
        remove_question: false,
        text: '',
    },
    hold :{
        question_key: '',
    }

    }
},
computed: {

},
methods: {

get_search_query(search_query){
  this.render_data = this.raw_data;

  let list = Object.values(this.render_data);
  let query = search_query ? search_query.toLowerCase() : ''; // Ensure query is always a string

    let filtered_data = list.filter(data => {
      const matchesState = data.State.toLowerCase().includes(query);
      const matchesName = data.OrganizationName.toLowerCase().includes(query);
      const matchesCity = data.City.toLowerCase().includes(query);
      const matchesAddress = data.HQAddress.toLowerCase().includes(query);

      // Return true if any of the conditions are met
      return matchesState || matchesName || matchesCity || matchesAddress
    });

    this.render_data = filtered_data;
},

async get_data(){
    this.isLoading = true
    const result = await getAllOrg();

    if(result.data){
        this.isLoading = false
        this.raw_data = [...result.data].reverse();
        this.render_data = [...result.data].reverse(); 
    }
},

process_render_data(raw_data){
    this.render_data = [];
    for (const [key, value] of Object.entries(raw_data)) {
      this.render_data.push(value);
    }
},

goto_editor(input_id){
    this.$router.push({ name: 'org-edit', params: { id: input_id} });
},

submit_modal(){
  this.isAddOrg = false;
  this.get_data();
},

close_modal(){
  this.isAddOrg = false;
},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

}
}
</script>

<style scoped>
.org-card {
  transition: all 0.3s ease;
  background: white;
}

.org-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.org-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.org-icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  background: #f0f4ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a6bff;
  font-size: 1.5rem;
}

.org-main-info {
  flex: 1;
  min-width: 0; /* Enables text truncation */
}

.org-name {
  font-size: 1.1rem;
  line-height: 1.3;
  max-width: 100%;
}

.contact-info {
  font-size: 0.85rem;
  max-width: 100%;
  margin-bottom: 0.3rem;
}

@media (max-width: 375px) { /* iPhone SE width */
  .org-header {
    gap: 0.5rem;
  }
  
  .org-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 1.2rem;
  }
}

.location-details {
  padding: 0.5rem 0;
}

.detail-row {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.95rem;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  height: 100%;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 768px) {
  .org-card {
    padding: 1rem;
  }

  .org-header {
    margin-bottom: 1rem;
  }

  .location-details {
    margin: 1rem 0;
  }

  .action-buttons {
    text-align: left;
    margin-top: 1rem;
  }

  .detail-row {
    font-size: 0.9rem;
  }
}
</style>

<style scoped>
.org-card {
  background: white;
  transition: all 0.3s ease;
  border: 1px solid #e9ecef !important;
}

.org-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.1) !important;
}

.org-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.org-icon {
  background: #e8f7ff;
  padding: 1rem;
  border-radius: 12px;
}

.org-name {
  color: #2c3e50;
  font-weight: 600;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-info {
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 0.9rem;
}

.contact-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-details {
  padding: 0.5rem 0;
}

.detail-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location-text {
  color: #495057;
  font-size: 0.95rem;
}

.action-btn {
  background: var(--primary-color);
  border: none;
  padding: 0.6rem 1.2rem;
  transition: all 0.3s ease;
}

.action-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-2px);
}
</style>