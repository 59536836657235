import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

//2025-1-12

export const getAuditTrails = async (startDate, endDate, retryAttempt = false) => {
    const authStore = useAuthStore();
    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/audit_trails`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`
            },
            params: {
                start_date: startDate,
                end_date: endDate
            }
        });
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return getAllOrgArray(true); // Retry after refreshing
        }
        throw error;
    }
};
