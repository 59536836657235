<script>
  import { registerUser } from "@/services/userServices"
  import { getAllClinics } from "@/services/clinicServices"
  import { getAllOrg } from "@/services/orgServices"
  import TitleHeader from "@/components/TitleHeader.vue"
  import { useAuthStore } from '@/stores/authStore'
  
  export default {
    components: {
      TitleHeader,
    },
    data() {
        return {
        authStore: useAuthStore(),
        user_data: {
            username: "",
            email: "",
            password: "",
            full_name: "",
            role: "",
            profession: "",
            specialization: "",
            clinic_name: "",
            contact_number: "",
            locations: [],
            additional_info: {},
            OrganizationUuid: '',
        },
        selectedClinic: "",
        clinicsJson: [],
        clinicsSelection: [],
        showPassword: false,
        organizations: [], // Add this to store organizations list
        }
    },
    watch: {
        selectedClinic(newClinic) {
        if (newClinic && !this.user_data.locations.includes(newClinic)) {
            this.user_data.locations.push(newClinic);
        }
        }
    },
    mounted() {
        if (this.authStore.userRole === 'clinic_admin') {
            this.get_clinics();
        } else if (this.authStore.userRole === 'admin') {
            this.getOrg();
        }
    },
    methods: {

    validateRequiredFields() {
        if (this.authStore.userRole === 'clinic_admin') {
            return (
                this.user_data.username &&
                this.user_data.email &&
                this.user_data.password &&
                this.user_data.full_name &&
                this.user_data.role &&
                this.user_data.locations.length > 0
            );
        } else if (this.authStore.userRole === 'admin') {
            return (
                this.user_data.username &&
                this.user_data.email &&
                this.user_data.password &&
                this.user_data.full_name &&
                this.user_data.role &&
                this.user_data.OrganizationUuid
            );
        }
    },

    async submit() {
        const authStore = useAuthStore();
        // Auto-assign role based on logged in user
        this.user_data.role = authStore.userRole === 'admin' ? 'admin' : 'user';

        // if(authStore.userRole === 'clinic_admin'){
        //     const userDetails = await authStore.fetchUserDetails()
        //     this.user_data.OrganizationUuid = userDetails.OrganizationUuid
        // }

        console.log("USER DATA",this.user_data);

        if (!this.validateRequiredFields()) {
            alert("Please fill in all required fields");
            return;
        }
        else {
            try {
                const response = await registerUser(this.user_data);
                if(response) {
                    this.$router.push('/users');
                }
            } catch (error) {
                console.log(error);
                if (error.response && error.response.data.detail === "Username or Email already registered") {
                    alert("Username or Email already registered. Please try different credentials.");
                }
            }
        }
    },

    async get_clinics() {
        const result = await getAllClinics();
        console.log(result.data)
        if (result.data) {
            for (let i = 0; i < result.data.length; i++) {
            this.clinicsJson[result.data[i].LocationUuid] = result.data[i].ClinicName;
            this.clinicsSelection.push({
                name: result.data[i].ClinicName,
                uuid: result.data[i].LocationUuid,
            });
            }
        }
    },

    async getOrg() {
        // Add API call to fetch organizations
        const result = await getAllOrg();
        if (result.data) {
            console.log(result.data)
            this.organizations = result.data;
        }
    },

    remove_clinic(index) {
        this.user_data.locations.splice(index, 1);
    },
  
      noSpaces(event) {
        this.user_data.username = event.target.value.replace(/\s+/g, '');
      },
  
      allowOnlyDigits(event) {
        this.user_data.contact_number = event.target.value.replace(/\D/g, '');
      },
  
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      }
    }
  }
</script>
  
<template>

    <div class="register-container">
        <div class="form-wrapper">
            <TitleHeader title="Register Users" />
        <div class="card modern-card">
            <div class="card-header">
            <h2 class="title">Create New User</h2>
            <p class="subtitle">Enter user details below</p>
            <p class="role-indicator">You will create a new {{ authStore.userRole === 'admin' ? 'clinic admin' : 'user' }} account</p>
        </div>

            <div class="card-body">
            <div class="form-grid">
                <!-- Username Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Username <span class="required">*</span></span>
                    <div class="input-with-icon">
                    <i class="fas fa-user icon"></i>
                    <input class="modern-input" v-model="user_data.username" placeholder="Enter username" @input="noSpaces">
                    </div>
                </label>
                </div>

                <!-- Email Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Email <span class="required">*</span></span>
                    <div class="input-with-icon">
                    <i class="fas fa-envelope icon"></i>
                    <input type="email" class="modern-input" v-model="user_data.email" placeholder="Enter email">
                    </div>
                </label>
                </div>

                <!-- Password Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Password <span class="required">*</span></span>
                    <div class="input-with-icon">
                    <i class="fas fa-lock icon"></i>
                    <input :type="showPassword ? 'text' : 'password'" class="modern-input" 
                            v-model="user_data.password" placeholder="Enter password">
                    <i class="fas eye-icon" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        @click="togglePasswordVisibility"></i>
                    </div>
                </label>
                </div>

                <!-- Full Name Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Full Name <span class="required">*</span></span>
                    <div class="input-with-icon">
                    <i class="fas fa-id-card icon"></i>
                    <input class="modern-input" v-model="user_data.full_name" placeholder="Enter full name">
                    </div>
                </label>
                </div>

                <!-- Contact Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Contact Number</span>
                    <div class="input-with-icon">
                    <i class="fas fa-phone icon"></i>
                    <input class="modern-input" v-model="user_data.contact_number" 
                            placeholder="Enter contact number" @input="allowOnlyDigits">
                    </div>
                </label>
                </div>

                <!-- Role Section -->
                <!-- <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Role <span class="required">*</span></span>
                    <div class="input-with-icon">
                    <i class="fas fa-user-tag icon"></i>
                    <select class="modern-input" v-model="user_data.role">
                        <option value="">Select role</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select>
                    </div>
                </label>
                </div> -->

                <!-- Profession Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Profession</span>
                    <div class="input-with-icon">
                    <i class="fas fa-briefcase icon"></i>
                    <input class="modern-input" v-model="user_data.profession" placeholder="Enter profession">
                    </div>
                </label>
                </div>

                <!-- Clinic Name Section -->
                <div class="form-section">
                <label class="modern-label">
                    <span class="label-text">Clinic Name</span>
                    <div class="input-with-icon">
                    <i class="fas fa-hospital icon"></i>
                    <input class="modern-input" v-model="user_data.clinic_name" placeholder="Enter clinic name">
                    </div>
                </label>
                </div>

                <!-- Specialization Section -->
                <div class="form-section full-width">
                <label class="modern-label">
                    <span class="label-text">Specialization</span>
                    <div class="input-with-icon">
                    <i class="fas fa-stethoscope icon"></i>
                    <input class="modern-input" v-model="user_data.specialization" placeholder="Enter specialization">
                    </div>
                </label>
                </div>

                <div class="form-section full-width">
                    <label class="modern-label">
                        <span class="label-text">{{ authStore.userRole === 'admin' ? 'Organization' : 'Locations' }} <span class="required">*</span></span>
                        <div class="locations-wrapper">
                            <!-- For clinic_admin: Show locations selection -->
                            <template v-if="authStore.userRole === 'clinic_admin'">
                                <div class="selected-locations">
                                    <div class="clinic-tag" v-for="(clinic_id, ind) in user_data.locations" :key="ind">
                                        <span>{{ clinicsJson[clinic_id] }}</span>
                                        <i class="fas fa-times remove-icon" @click="remove_clinic(ind)"></i>
                                    </div>
                                </div>
                                <div class="input-with-icon">
                                    <i class="fas fa-location-dot icon"></i>
                                    <select class="modern-input" v-model="selectedClinic">
                                        <option value="">Select location</option>
                                        <option v-for="(data, key) in clinicsSelection" :key="key" :value="data.uuid">
                                            {{ data.name }}
                                        </option>
                                    </select>
                                </div>
                            </template>
                            
                            <!-- For admin: Show organization selection -->
                            <template v-else-if="authStore.userRole === 'admin'">
                                <div class="input-with-icon">
                                    <i class="fas fa-building icon"></i>
                                    <select class="modern-input" v-model="user_data.OrganizationUuid">
                                        <option value="">Select organization</option>
                                        <option v-for="org in organizations" :key="org.uuid" :value="org.OrganizationUuid">
                                            {{ org.OrganizationName }}
                                        </option>
                                    </select>
                                </div>
                            </template>
                        </div>
                    </label>
                </div>
                
            </div>

            <div class="button-group">
                <button class="modern-button cancel" @click="$router.push('/users')">
                <i class="fas fa-times"></i> Cancel
                </button>
                <button class="modern-button submit" @click="submit()">
                <i class="fas fa-plus"></i> Create User
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>
.required {
    color: #dc3545;
    margin-left: 4px;
}
.locations-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
.selected-locations {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    min-height: 40px;
    padding: 0.5rem;
    border: 2px solid #e9ecef;
    border-radius: 12px;
}

.clinic-tag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #cefffd;
    border-radius: 8px;
    font-size: 0.9rem;
}

.remove-icon {
cursor: pointer;
color: #dc3545;
transition: color 0.2s ease;
}

.remove-icon:hover {
color: #c82333;
}

.register-container {
min-height: 100vh;
padding: 2rem;
background-color: #f8f9fa;
}

.form-wrapper {
max-width: 1200px;
margin: 0 auto;
}

.modern-card {
background: white;
border-radius: 20px;
box-shadow: 0 8px 30px rgba(0,0,0,0.08);
border: none;
padding: 2rem;
}

.card-header {
border: none;
background: none;
padding: 0 0 2rem 0;
}

.title {
font-size: 2rem;
color: #2c3e50;
margin-bottom: 0.5rem;
font-weight: 600;
}

.subtitle {
color: #6c757d;
font-size: 1rem;
}

.form-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
gap: 1.5rem;
}

.form-section {
position: relative;
}

.full-width {
grid-column: 1 / -1;
}

.modern-label {
width: 100%;
}

.label-text {
display: block;
margin-bottom: 0.5rem;
color: #495057;
font-weight: 500;
}

.input-with-icon {
position: relative;
}

.icon {
position: absolute;
left: 1rem;
top: 50%;
transform: translateY(-50%);
color: #6c757d;
}

.eye-icon {
position: absolute;
right: 1rem;
top: 50%;
transform: translateY(-50%);
cursor: pointer;
color: #6c757d;
}

.modern-input {
width: 100%;
padding: 0.8rem 1rem 0.8rem 2.8rem;
border: 2px solid #e9ecef;
border-radius: 12px;
font-size: 1rem;
transition: all 0.3s ease;
}

.modern-input:focus {
border-color: var(--primary-color);
box-shadow: 0 0 0 3px rgba(15, 186, 178, 0.1);
outline: none;
}

.button-group {
display: flex;
gap: 1rem;
justify-content: flex-end;
margin-top: 2rem;
}

.modern-button {
padding: 0.8rem 1.5rem;
border-radius: 12px;
font-weight: 500;
border: none;
cursor: pointer;
transition: all 0.3s ease;
display: flex;
align-items: center;
gap: 0.5rem;
}

.submit {
background: var(--primary-color);
color: white;
}

.submit:hover {
background: var(--primary-hover);
transform: translateY(-2px);
}

.cancel {
background: #dc3545;
color: white;
}

.cancel:hover {
background: #c82333;
transform: translateY(-2px);
}

@media (max-width: 768px) {
.register-container {
    padding: 1rem;
}

.modern-card {
    padding: 1.5rem;
}

.button-group {
    flex-direction: column;
}

.modern-button {
    width: 100%;
    justify-content: center;
}
}

.role-indicator {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}
</style>
  
  