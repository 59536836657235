<script>
import { getAuditTrails } from '@/services/loginauditServices'
import { RectangleEllipsis } from 'lucide-vue-next'

export default {
  name: 'Audits',
  components: {
    RectangleEllipsis,
  },
  data() {
    return {
        startDate: '',
        endDate: '',
        auditData: [],
        loading: false,
        hasSearched: false,
        currentPage: 1,
        limit: 10,
        totalItems: 0,
        searchQuery: '',
    }
  },

  methods: {
    async fetchAuditData() {
      if (!this.startDate || !this.endDate) {
        alert('Please select both start and end dates')
        return
      }

      this.loading = true
      this.hasSearched = true

      const adjustedEndDate = new Date(this.endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      const formattedEndDate = adjustedEndDate.toISOString().split('T')[0];

      try {
        const result = await getAuditTrails(this.startDate,formattedEndDate)
        
        this.auditData = result.reverse();
        // You might need to implement pagination logic here depending on API response
      } catch (error) {
        console.error('Error fetching audit data:', error)
        this.auditData = []
      } finally {
        this.loading = false
      }
    },

    convertToMalaysiaTime(utcDateTime) {
        // Parse the UTC datetime string
        const utcDate = new Date(utcDateTime + 'Z'); // Add 'Z' to indicate UTC
        // Malaysia Time is UTC +8
        const malaysiaTimeOffset = 8 * 60; // Offset in minutes
        // Create a new Date object with the Malaysia Time offset
        const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset * 60 * 1000);
        // Format the date to a readable string
        return malaysiaDate.toISOString().replace('T', ' ').slice(0, 19);
    },

    formatDate(dateString) {
      const converted = this.convertToMalaysiaTime(dateString)
      return new Date(converted).toLocaleString()
    },

  },

  computed: {
    filteredData() {
        if (!this.searchQuery) return this.auditData;
        
        const query = this.searchQuery.toLowerCase();
        return this.auditData.filter(item => 
        item.Username.toLowerCase().includes(query) ||
        item.FullName.toLowerCase().includes(query) ||
        item.OrganizationName.toLowerCase().includes(query)
        );
    },
    
    paginatedData() {
        const startIndex = (this.currentPage - 1) * Number(this.limit);
        const endIndex = startIndex + Number(this.limit);
        return this.filteredData.slice(startIndex, endIndex);
    },
    
    totalPages() {
        return Math.ceil(this.filteredData.length / this.limit);
    },
    visiblePages() {
        let pages = [];
        let start = Math.max(2, this.currentPage - 2);
        let end = Math.min(this.totalPages - 1, this.currentPage + 2);

        if (this.currentPage <= 4) {
        start = 2;
        end = Math.min(6, this.totalPages - 1);
        }

        if (this.currentPage >= this.totalPages - 3) {
        start = Math.max(this.totalPages - 5, 2);
        end = this.totalPages - 1;
        }

        for (let i = start; i <= end; i++) {
        pages.push(i);
        }
        return pages;
    }
  },

  mounted() {
    const today = new Date()
    this.endDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
    
    const sevenDaysAgo = new Date(today)
    sevenDaysAgo.setDate(today.getDate() - 7)
    this.startDate = `${sevenDaysAgo.getFullYear()}-${String(sevenDaysAgo.getMonth() + 1).padStart(2, '0')}-${String(sevenDaysAgo.getDate()).padStart(2, '0')}`    
    this.fetchAuditData()
  }
}
</script>

<template>
    <div class="text-center mt-4">
        <h3><RectangleEllipsis/> Login Audits</h3>
    </div>
  
    <div class="audit-container">
      <div class="audit-card">

        

        <div class="date-filters">
          <div class="date-input-group">
            <label>Start Date</label>
            <input type="date" v-model="startDate" class="custom-input">
          </div>
          <div class="date-input-group">
            <label>End Date</label>
            <input type="date" v-model="endDate" class="custom-input">
          </div>
        </div>
  
        <div class="filters-section">
            <div class="filter-group">
              <div class="advanced-filters">
                <div class="filter-item">
                  <input 
                    type="text" 
                    v-model="searchQuery" 
                    placeholder="Search username, full name or organization..." 
                    class="search-input"
                  >
                </div>
                <div class="filter-item">
                  <label>Items per page</label>
                  <select v-model="limit" class="custom-input mx-2">
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </div>
            </div>
            <button @click="fetchAuditData" class="search-button">
              <i class="fas fa-search"></i> Search
            </button>
          </div>          

        <div v-if="loading" class="loader-container">
          <div class="loader"></div>
        </div>
  
        <div v-else-if="auditData.length" class="results-section">
          <div class="table-responsive">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Username</th>
                  <th>Full Name</th>
                  <th>Organization</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="audit in paginatedData" :key="audit.Username + audit.Timestamp">
                  <td>{{ formatDate(audit.Timestamp) }}</td>
                  <td>{{ audit.Username }}</td>
                  <td>{{ audit.FullName }}</td>
                  <td>{{ audit.OrganizationName }}</td>
                </tr>
              </tbody>
            </table>

            <div v-if="filteredData.length === 0 && searchQuery" class="no-results">
                <i class="fas fa-search"></i>
                <p>No results found for "{{ searchQuery }}"</p>
            </div>
              
              <div class="pagination-section">
                <div class="pagination-controls">
                  <button 
                    :disabled="currentPage === 1" 
                    @click="currentPage--" 
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </button>
              
                  <div class="page-numbers">
                    <button 
                      v-if="totalPages > 0"
                      @click="currentPage = 1"
                      class="page-number-button"
                      :class="{ active: currentPage === 1 }"
                    >
                      1
                    </button>
              
                    <span v-if="currentPage > 4" class="ellipsis">...</span>
              
                    <button 
                      v-for="page in visiblePages" 
                      :key="page"
                      @click="currentPage = page"
                      class="page-number-button"
                      :class="{ active: currentPage === page }"
                    >
                      {{ page }}
                    </button>
              
                    <span v-if="currentPage < totalPages - 3" class="ellipsis">...</span>
              
                    <button 
                      v-if="totalPages > 1"
                      @click="currentPage = totalPages"
                      class="page-number-button"
                      :class="{ active: currentPage === totalPages }"
                    >
                      {{ totalPages }}
                    </button>
                  </div>
              
                  <button 
                    :disabled="currentPage === totalPages" 
                    @click="currentPage++" 
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>    
          </div>
        </div>
          
      </div>
    </div>
</template>

<style scoped>
.audit-container {
  padding: 1rem;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .audit-container {
    padding: 2rem;
  }
}

.audit-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  padding: 1.5rem;
  margin: 0 auto;
}

.audit-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .audit-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.filters-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .filters-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.advanced-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.confidence-bar {
  background: #e2e8f0;
  height: 8px;
  border-radius: 4px;
  position: relative;
  margin-top: 0.5rem;
}

.confidence-fill {
  background: #2cbbb2;
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.analysis-badge, .audit-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-normal { background: #dcfce7; color: #166534; }
.status-abnormal { background: #fee2e2; color: #991b1b; }
.status-pending { background: #fef3c7; color: #92400e; }
.status-completed { background: #dbeafe; color: var(--primary-color); }

.thumbnail {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.page-info {
  background: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: #475569;
  font-weight: 500;
}

.audit-container {
  padding: 2rem;
  min-height: 100vh;
}

.audit-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.audit-title {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
}

.date-filters {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;  /* Default for mobile */
}

/* Add media query for larger screens */
@media (min-width: 768px) {
  .date-filters {
    grid-template-columns: 3fr 3fr;  /* Two columns for desktop */
  }
}

.date-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-input-group label {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 500;
}

.custom-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
}

.search-input{
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
  width:100%;
}

.search-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }

.custom-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.search-button {
  background: var(--primary-color);
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.search-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.loader-container {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.loader {
  width: 48px;
  height: 48px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table-container {
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table th {
  background: #f8fafc;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #475569;
  border-bottom: 2px solid #e2e8f0;
  white-space: nowrap;
}

.custom-table td {
  padding: 1rem;
  color: #64748b;
  border-bottom: 1px solid #e2e8f0;
}

.custom-table tbody tr:hover {
  background-color: #f8fafc;
}

.no-results {
  text-align: center;
  padding: 3rem;
  color: #64748b;
  font-size: 1.1rem;
}

.thumbnail {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.page-info {
  background: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: #475569;
  font-weight: 500;
}
</style>

<style scoped>
.text-center {
  text-align: center;
}

.custom-table th,
.custom-table td {
  padding: 1rem;
  vertical-align: middle;
}

.analysis-badge,
.audit-badge {
  display: inline-block;
}
</style>

<style scoped>
.pagination-section {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-numbers {
  display: flex;
  gap: 0.1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-button,
.page-number-button {
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #64748b;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-number-button.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.pagination-button:hover:not(:disabled),
.page-number-button:hover:not(.active) {
  background: #f1f5f9;
  color: #1e293b;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ellipsis {
  color: #64748b;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .page-numbers {
    max-width: 200px;
    overflow-x: auto;
    padding: 0.5rem;
  }

  .pagination-button,
  .page-number-button {
    min-width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }
}
</style>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.zoom-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.image-container:hover .zoom-button {
  opacity: 1;
}

.audit-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.audit-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.action-cell {
  width: 120px;
}

.details-cell{
  font-size: 0.85rem;
}
</style>

<style scoped>
.status-filter {
  margin: 1rem 0;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  width: 200px;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}
</style>