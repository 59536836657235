
<template>

    <!-- LOOPING TABLE CASES -->
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <Modal
      :isVisible="modal.deactivateUser"
      promptText="Are you sure u want to deactivate this user ?"
      yesText="Confirm"
      noText="Cancel"
      @yes="deactivate_user()"
      @no="close_deactivate()"
    /> 

    <div class="text-center mt-4">
        <h3><UserIcon/> Users</h3>
    </div>

    <div class="container my-1">

      <SearchBar
      searchPlaceholder="Search Users Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      class="mb-3"
      />

      <div class="card round-card shadow-sm">
        <div class="card-body">
          <!-- Header Section -->
          <div class="flex-md ">
            <div class="row mx-1 mb-2">
              <button 
                class="btn" 
                style="background-color: var(--primary-color); color: white; padding:5px;" 
                @click="$router.push('/users/register')"
              >
                <i class="fa-solid fa-plus"></i> Register Users
              </button>
            </div>  
          </div>
      
          <!-- Users List -->
          <Table :input_object="render_data">
            <template v-slot="{ data }">
              <div class="user-card p-3 mb-3 border rounded hover-shadow">
                <div class="row align-items-center">
                  <!-- User Avatar & Name -->
                  <div class="col-md-3 my-1">
                    <div class="d-flex align-items-center">
                      <div class="avatar-circle me-3">
                        {{ data.full_name.charAt(0) }}
                      </div>
                      <div>
                        <h6 class="mb-1">{{ data.full_name }}</h6>
                        <span class="badge" :class="getRoleBadgeClass(data.role)">
                          {{ data.role.replace("_", " ") }}
                        </span>
                      </div>
                    </div>
                  </div>
      
                  <!-- User Details -->
                  <div class="col-md-6">
                    <div class="user-details">
                      <div class="detail-item" title="username">
                        <i class="fas fa-user-tag me-2"></i>
                        <span>{{ data.username }}</span>
                      </div>
                      <div class="detail-item" title="clinic name">
                        <i class="fas fa-clinic-medical me-2"></i>
                        <span>{{ data.clinic_name }}</span>
                      </div>
                      <div class="detail-item" title="contact number">
                        <i class="fas fa-phone me-2"></i>
                        <span>{{ data.contact_number }}</span>
                      </div>
                      <div class="detail-item" title="email">
                        <i class="fas fa-envelope me-2"></i>
                        <span>{{ data.email }}</span>
                      </div>
                    </div>
                  </div>
      
                  <!-- Action Buttons -->
                  <div class="col-md-3 text-end">
                    <button 
                      class="btn btn-primary btn-sm action-btn me-2" 
                      @click="$router.push(`/users/edit/${data.uuid}`)"
                    >
                      <i class="fa-regular fa-pen-to-square me-2"></i>
                      Edit
                    </button>
                    <button 
                      v-if="authStore.userRole === 'admin'"
                      class="btn btn-danger btn-sm action-btn" 
                      @click="ask_modal(data.uuid)"
                    >
                      <i class="fa-regular fa-trash-can me-2"></i>
                      Deactivate
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </Table>
        </div>
      </div>


    </div>

    





</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Table from "@/components/ListWithPaginate.vue";
import { getAllUser, deactivateUser } from "@/services/userServices"
import AddUsers from "@/pages/Users/components/AddUsers.vue"
import {UserIcon} from 'lucide-vue-next'


export default{
components: {
Loading,
Modal,
SearchBar,
Table,
AddUsers,
UserIcon
},

created(){
this.get_data();
console.warn("Created DOM!");
},
data() {
    return {
    authStore: useAuthStore(),
    raw_data : [],
    render_data : [],
    current_page : 0,
    page_limit : 10,
    isAddUser: false,
    isLoading: false,
    search_query: '',
    click_categories: false,

    modal :{
        deactivateUser : false,
        text: '',
    },
    hold :{
        user_uuid: '',
    }

    }
},
computed: {

},
methods: {

getRoleBadgeClass(role) {
  switch(role.toLowerCase()) {
    case 'admin':
      return 'bg-primary text-white';
    case 'clinic_admin':
      return 'bg-success text-white';
    default:
      return 'bg-secondary text-white';
  }
},

get_search_query(search_query){
  this.render_data = this.raw_data;

  let list = Object.values(this.render_data);
  let query = search_query ? search_query.toLowerCase() : ''; // Ensure query is always a string

    let filtered_data = list.filter(data => {
      const matchesUsername = data.username.toLowerCase().includes(query);
      const matchesRole = data.role.toLowerCase().includes(query);
      const matchesClinicName = data.clinic_name.toLowerCase().includes(query);
      const matchesFullName = data.full_name.toLowerCase().includes(query);
      const matchesEmail = data.email.toLowerCase().includes(query);
      // Return true if any of the conditions are met
      return matchesUsername || matchesRole || matchesClinicName || matchesFullName || matchesEmail
    });

    this.render_data = filtered_data;
},

async get_data(){
    this.isLoading = true
    const result = await getAllUser();

    console.log("woi",result);

    // if(result.users){
    //     this.isLoading = false
    //     this.raw_data = [...result.users].reverse(); 
    //     this.render_data = [...result.users].reverse(); 
    // }

    if(result.users){
        this.isLoading = false;
        // Filter users based on logged in user role
        if (this.authStore.userRole === 'admin') {
            // Admin only sees clinic_admin users
            this.raw_data = result.users.filter(user => user.role === 'clinic_admin').reverse();
        } else {
            // clinic_admin only sees regular users
            this.raw_data = result.users.filter(user => user.role === 'user').reverse();
        }
        this.render_data = this.raw_data;
    }

},

process_render_data(raw_data){
    this.render_data = [];
    for (const [key, value] of Object.entries(raw_data)) {
      this.render_data.push(value);
    }
},

goto_editor(input_id){
    this.$router.push({ name: 'users-edit', params: { id: input_id} });
},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

ask_modal(uuid){
  console.log(uuid);
  this.hold.user_uuid = uuid;

  this.modal.deactivateUser = true
},

async deactivate_user(){
  const result = await deactivateUser(this.hold.user_uuid);
  console.log(result);
  this.modal.deactivateUser = false;
  this.get_data();
},

close_deactivate(){
  this.modal.deactivateUser = false;
},

submit_modal(){
  this.isAddUser = false;
  this.get_data();
},

close_modal(){
  this.isAddUser = false;
},

}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:100% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:5px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: var(--primary-color);
color: white;
padding:8px;
}

.red-btn{
background-color: #ff0000;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.bg-color{
background-color: var(--primary-color)
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 12px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.card.hidden{
border: none;
background-color: rgba(255, 255, 255, 0)
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  }
  
.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

</style>

<style scoped>
.user-card {
  transition: all 0.3s ease;
  background: white;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.avatar-circle {
  width: 40px;
  height: 40px;
  min-width: 40px; /* Prevents shrinking */
  min-height: 40px; /* Prevents shrinking */
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  flex-shrink: 0; /* Prevents flexbox from squeezing the circle */
}

/* Handle long names by adding text overflow */
.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px); /* Accounts for avatar width + margin */
}

.user-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.detail-item {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
}

.badge {
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
}

.hover-shadow:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .user-details {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .col-md-3.text-end {
    margin-top: 1rem;
    text-align: left !important;
  }
}

.action-btn {
  padding: 0.6rem 1.2rem;
  transition: all 0.3s ease;
  border: none;
}

.action-btn:hover {
  transform: translateY(-2px);
}

.btn-primary.action-btn {
  background: var(--primary-color);
}

.btn-primary.action-btn:hover {
  background: var(--primary-hover);
}

.btn-danger.action-btn:hover {
  background: #c82333;
}
</style>

