import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const registerIntegration = async (payload, retryAttempt = false) => {
    const authStore = useAuthStore();
    console.log(payload);

    try {
        const response = await axios.post(`${BACKEND_URL}/nora_superadmin/create-integration-key?user_uuid=${payload.user_uuid}&description=${payload.description}`, {}, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
        });

        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerIntegration(payload, true); // Retry after refreshing
        }
        throw error;
    }
}

export const getIntegration = async (user_uuid, organization_uuid, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/integration-keys`,{
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                organization_uuid,
                user_uuid, 
            }
        });

        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllIntegration(user_uuid, organization_uuid, true); // Retry after refreshing
        }
        throw error;
    }
}

export const getAllIntegration = async (retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/integration-keys`,{
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
        });

        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllIntegration(true); // Retry after refreshing
        }
        throw error;
    }
};

export const deactivateIntegration = async (uuid, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.patch(`${await authStore.get_url()}/deactivate-integration-key/${uuid}`, {} ,{
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return deactivateIntegration(uuid, true); // Retry after refreshing
        }
        throw error;
    }
};