<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import { getCurrentOrg, editOrg } from "@/services/orgServices"
import StatesJSON from "@/assets/states.json"
import TitleHeader from "@/components/TitleHeader.vue"

export default{
components: {
    Loading,
    Modal,
    TitleHeader
},

created(){
    this.org_id = this.$route.params.id;
    this.get_data(this.org_id);
    console.warn("Created DOM!");
},

data() {return {   
    org_id: '',
    raw_org_data: [],
    org_data: {
        org_name: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        latitude: "",
        longitude: ""
    },
    isLoading: false,
    modal: {
        isSubmitting: false,
    },
    isEditing: false,
    states: StatesJSON
}

},
computed: {
},
methods: {
    async get_data(uuid){
        this.isLoading = true
        const result = await getCurrentOrg(uuid);

        if(result.data){
            this.isLoading = false;
            this.raw_org_data  = result.data;
        }

        console.log(this.raw_org_data);

        this.convert_data(this.raw_org_data);
    },

    convert_data(input_data){
        this.org_data = {
            organization_name: input_data.OrganizationName,
            hq_address: input_data.HQAddress,
            city: input_data.City,
            state: input_data.State,
            country: "Malaysia",
            postal_code: input_data.PostalCode,
            latitude: input_data.Latitude,
            longitude: input_data.Longitude,
            contact_email: input_data.ContactEmail,
            contact_phone: input_data.ContactPhone,
            subscription_plan: input_data.SubscriptionPlan,
            subscription_type: input_data.SubscriptionType,
            subscription_status: input_data.SubscriptionStatus
        },

        console.log(this.org_data);
    },

    close_modal(){
        this.modal.isSubmitting = false;
        this.convert_data(this.raw_org_data);
    },

    async submit_edit(){
        this.isLoading = true;
        this.isEditing = false;
        this.modal.isSubmitting = false;
        console.log(this.org_data);
        const result = await editOrg(this.org_data, this.org_id);
        if(result){
            this.isLoading = false;
        }

        this.get_data(this.org_id);
    }
}
}
</script>

<template>
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <Modal
      :isVisible="modal.isSubmitting"
      promptText="Are you sure you want to edit this?"
      yesText="Confirm"
      noText="Cancel"
      @yes="submit_edit()"
      @no="close_modal()"
    /> 

    <div class="register-container">
        <div class="form-wrapper">
            <TitleHeader title="Edit Organization" />
            <div class="card modern-card">
                <div class="card-header">
                    <h2 class="title">{{ raw_org_data.OrganizationName }}</h2>
                    <p class="subtitle">Edit organization details below</p>
                </div>

                <div class="card-body">
                    <div class="form-grid">
                        <!-- Organization Name Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Organization Name</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-building icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.organization_name">
                                </div>
                            </label>
                        </div>

                        <!-- Address Section -->
                        <div class="form-section full-width">
                            <label class="modern-label">
                                <span class="label-text">HQ Address</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-map-marker-alt icon"></i>
                                    <textarea class="modern-input" :disabled="!isEditing" v-model="org_data.hq_address"></textarea>
                                </div>
                            </label>
                        </div>

                        <!-- Contact Email Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Contact Email</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-envelope icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.contact_email">
                                </div>
                            </label>
                        </div>

                        <!-- Contact Phone Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Contact Phone</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-phone icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.contact_phone">
                                </div>
                            </label>
                        </div>

                        <!-- City Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">City</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-city icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.city">
                                </div>
                            </label>
                        </div>

                        <!-- State Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">State</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-map icon"></i>
                                    <select class="modern-input" :disabled="!isEditing" v-model="org_data.state">
                                        <option value="">Choose Your State</option>
                                        <option v-for="(data, key) in states" :key="key" :value="data">{{ data }}</option>
                                    </select>
                                </div>
                            </label>
                        </div>

                        <!-- Postal Code Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Postal Code</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-mail-bulk icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.postal_code">
                                </div>
                            </label>
                        </div>

                        <!-- Subscription Details -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Subscription Plan</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-tag icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.subscription_plan">
                                </div>
                            </label>
                        </div>

                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Subscription Type</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-cube icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.subscription_type">
                                </div>
                            </label>
                        </div>

                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Subscription Status</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-check-circle icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="org_data.subscription_status">
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="button-group">
                        <button class="modern-button cancel" v-if="!isEditing" @click="$router.push('/organizations')">
                            <i class="fas fa-arrow-left"></i> Back
                        </button>
                        <button class="modern-button submit" v-if="!isEditing" @click="isEditing = true">
                            <i class="fas fa-edit"></i> Edit
                        </button>
                        <button class="modern-button cancel" v-if="isEditing" @click="isEditing = false">
                            <i class="fas fa-times"></i> Cancel
                        </button>
                        <button class="modern-button submit" v-if="isEditing" @click="modal.isSubmitting = true; isEditing = false">
                            <i class="fas fa-save"></i> Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* Use the same styles as in OrganizationRegister.vue */
.register-container {
    min-height: 100vh;
    padding: 2rem;
    background-color: #f8f9fa;
}

.form-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.modern-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0,0,0,0.08);
    border: none;
    padding: 2rem;
}

.card-header {
    border: none;
    background: none;
    padding: 0 0 2rem 0;
}

.title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.subtitle {
    color: #6c757d;
    font-size: 1rem;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
}

.form-section {
    position: relative;
}

.full-width {
    grid-column: 1 / -1;
}

.modern-label {
    width: 100%;
}

.label-text {
    display: block;
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: 500;
}

.input-with-icon {
    position: relative;
}

.icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
}

.modern-input {
    width: 100%;
    padding: 0.8rem 1rem 0.8rem 2.8rem;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.modern-input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(15, 186, 178, 0.1);
    outline: none;
}

.modern-input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

textarea.modern-input {
    min-height: 100px;
    resize: vertical;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.modern-button {
    padding: 0.8rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.submit {
    background: var(--primary-color);
    color: white;
}

.submit:hover {
    background: var(--primary-hover);
    transform: translateY(-2px);
}

.cancel {
    background: #dc3545;
    color: white;
}

.cancel:hover {
    background: #c82333;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .register-container {
        padding: 1rem;
    }

    .modern-card {
        padding: 1.5rem;
    }

    .button-group {
        flex-direction: column;
    }

    .modern-button {
        width: 100%;
        justify-content: center;
    }
}
</style>
