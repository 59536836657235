<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import { getAllOrg } from "@/services/orgServices";
import { getAllClinics } from "@/services/clinicServices";
import { editUser, getCurrentUser } from "@/services/userServices";
import TitleHeader from "@/components/TitleHeader.vue"
import { useAuthStore } from '@/stores/authStore'

export default{
components: {
    Loading,
    Modal,
    TitleHeader
},

created(){
    this.user_id = this.$route.params.id;
    this.get_data(this.user_id);
    this.get_clinics();
    console.warn("Created DOM!");
},

watch: {
    // Watch for changes to selectedClinic and add the clinic to locations automatically
    selectedClinic(newClinic) {
      if (newClinic && !this.user_data.locations.includes(newClinic)) {
        this.user_data.locations.push(newClinic);

        console.log(this.user_data.locations);
      }
    },
    
  },

data() {return {   
    authStore: useAuthStore(),
    user_id: '',
    raw_user_data: [],
    user_data: {},
    selectedClinic: "",
    clinicsJson: [],
    clinicsSelection: [],
    isLoading: false,
    modal: {
        isSubmitting: false,
    },
    isEditing: false,
    organizations: [],
}

},
computed: {
},
methods: {
    async get_data(uuid) {
      this.isLoading = true;
      const result = await getCurrentUser(uuid);

      // Fetch data based on role
      if (this.authStore.userRole === 'clinic_admin') {
        await this.get_clinics();
      } else if (this.authStore.userRole === 'admin') {
        await this.get_org();
      }

      if (result.user_info) {
        this.isLoading = false;
        this.raw_user_data = result.user_info;
      }

      this.convert_data(this.raw_user_data);
    },

    async get_org() {
      const result = await getAllOrg();
      if (result.data) {
        this.organizations = result.data;
      }
    },

    async get_clinics(){
        const result = await getAllClinics();

        if(result.data){
            for(let i=0; i<result.data.length; i++){
                this.clinicsJson[result.data[i].LocationUuid] = result.data[i].ClinicName;

                let temp = {
                    name: result.data[i].ClinicName,
                    uuid: result.data[i].LocationUuid,
                }

                this.clinicsSelection.push(temp)
            }
        }

        console.log(this.clinicsJson, this.clinicsSelection);
    },

    convert_data(input_data){
        this.user_data = {
            full_name: input_data.FullName,
            profession: input_data.Profession,
            specialization: input_data.Specialization,
            clinic_name: input_data.ClinicName,
            contact_number: input_data.ContactNumber,
            locations: input_data.Locations ? Object.values(input_data.Locations) : [],
            additional_info: input_data.AdditionalInfo,
            OrganizationUuid: input_data.OrganizationUuid,
        },

        console.log('USER',this.user_data);
    },

    remove_clinic(key){
        this.user_data.locations.splice(key, 1);  // This will remove the item at the index and shift the rest
        console.log(this.user_data.locations);
    },

    close_modal(){
        this.modal.isSubmitting = false;
        this.convert_data(this.raw_user_data);
    },

    async submit_edit(){
        this.isLoading = true;
        this.isEditing = false;
        this.modal.isSubmitting = false;

        console.log(this.user_data, this.user_id);
        const result = await editUser(this.user_data, this.user_id);
        if(result){
            this.isLoading = false;
        }

        this.get_data(this.user_id);
    },
}
}
</script>

<template>
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <Modal
      :isVisible="modal.isSubmitting"
      promptText="Are you sure you want to edit this?"
      yesText="Confirm"
      noText="Cancel"
      @yes="submit_edit()"
      @no="close_modal()"
    /> 

    <div class="register-container">
        <div class="form-wrapper">
                <TitleHeader title="Edit User" />
            <div class="card modern-card">
                <div class="card-header">
                    <h2 class="title">{{ user_data.full_name }}</h2>
                    <p class="subtitle">Edit user details below</p>
                </div>

                <div class="card-body">
                    <div class="form-grid">
                        <!-- Full Name Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Full Name</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-id-card icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="user_data.full_name">
                                </div>
                            </label>
                        </div>

                        <!-- Profession Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Profession</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-briefcase icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="user_data.profession">
                                </div>
                            </label>
                        </div>

                        <!-- Specialization Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Specialization</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-stethoscope icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="user_data.specialization">
                                </div>
                            </label>
                        </div>

                        <!-- Clinic Name Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Clinic Name</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-hospital icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="user_data.clinic_name">
                                </div>
                            </label>
                        </div>

                        <!-- Contact Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Contact Number</span>
                                <div class="input-with-icon">
                                    <i class="fas fa-phone icon"></i>
                                    <input class="modern-input" :disabled="!isEditing" v-model="user_data.contact_number">
                                </div>
                            </label>
                        </div>

                        <!-- Organization Section - Admin Only -->
                        <div class="form-section" v-if="authStore.userRole === 'admin'">
                            <label class="modern-label">
                            <span class="label-text">Organization</span>
                            <div class="input-with-icon">
                                <i class="fas fa-building icon"></i>
                                <select class="modern-input" :disabled="!isEditing" v-model="user_data.OrganizationUuid">
                                <option value="">Select organization</option>
                                <option v-for="org in organizations" :key="org.OrganizationUuid" :value="org.OrganizationUuid">
                                    {{ org.OrganizationName }}
                                </option>
                                </select>
                            </div>
                            </label>
                        </div>

                        <!-- Locations Section - Clinic Admin Only -->
                        <div class="form-section full-width" v-if="authStore.userRole === 'clinic_admin'">
                            <label class="modern-label">
                            <span class="label-text">Locations</span>
                            <div class="locations-wrapper">
                                <div class="selected-locations">
                                <div class="clinic-tag" v-for="(clinic_id, ind) in user_data.locations" :key="ind">
                                    <span>{{ clinicsJson[clinic_id] }}</span>
                                    <i class="fas fa-times remove-icon" v-if="isEditing" @click="remove_clinic(ind)"></i>
                                </div>
                                </div>
                                <div class="input-with-icon" v-if="isEditing">
                                <i class="fas fa-location-dot icon"></i>
                                <select class="modern-input" v-model="selectedClinic">
                                    <option value="">Select location</option>
                                    <option v-for="(data, key) in clinicsSelection" :key="key" :value="data.uuid">
                                    {{ data.name }}
                                    </option>
                                </select>
                                </div>
                            </div>
                            </label>
                        </div>

                        
                    </div>

                    <div class="button-group">
                        <button class="modern-button cancel" v-if="!isEditing" @click="$router.push('/users')">
                            <i class="fas fa-arrow-left"></i> Back
                        </button>
                        <button class="modern-button submit" v-if="!isEditing" @click="isEditing = true">
                            <i class="fas fa-edit"></i> Edit
                        </button>
                        <button class="modern-button cancel" v-if="isEditing" @click="isEditing = false">
                            <i class="fas fa-times"></i> Cancel
                        </button>
                        <button class="modern-button submit" v-if="isEditing" @click="modal.isSubmitting = true; isEditing = false">
                            <i class="fas fa-save"></i> Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.register-container {
    min-height: 100vh;
    padding: 2rem;
    background-color: #f8f9fa;
}

.form-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.modern-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0,0,0,0.08);
    border: none;
    padding: 2rem;
}

.card-header {
    border: none;
    background: none;
    padding: 0 0 2rem 0;
}

.title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.subtitle {
    color: #6c757d;
    font-size: 1rem;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
}

.form-section {
    position: relative;
}

.full-width {
    grid-column: 1 / -1;
}

.modern-label {
    width: 100%;
}

.label-text {
    display: block;
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: 500;
}

.input-with-icon {
    position: relative;
}

.icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
}

.modern-input {
    width: 100%;
    padding: 0.8rem 1rem 0.8rem 2.8rem;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.modern-input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(15, 186, 178, 0.1);
    outline: none;
}

.modern-input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

.locations-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.selected-locations {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    min-height: 40px;
    padding: 0.5rem;
    border: 2px solid #e9ecef;
    border-radius: 12px;
}

.clinic-tag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #cefffd;
    border-radius: 8px;
    font-size: 0.9rem;
}

.remove-icon {
    cursor: pointer;
    color: #dc3545;
    transition: color 0.2s ease;
}

.remove-icon:hover {
    color: #c82333;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.modern-button {
    padding: 0.8rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.submit {
    background: var(--primary-color);
    color: white;
}

.submit:hover {
    background: var(--primary-hover);
    transform: translateY(-2px);
}

.cancel {
    background: #dc3545;
    color: white;
}

.cancel:hover {
    background: #c82333;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .register-container {
        padding: 1rem;
    }

    .modern-card {
        padding: 1.5rem;
    }

    .button-group {
        flex-direction: column;
    }

    .modern-button {
        width: 100%;
        justify-content: center;
    }
}
</style>
