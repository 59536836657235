<template>
    <div class="register-container">
        <div class="form-wrapper">
            <TitleHeader title="Register Organization" />
            <div class="card modern-card">
                <div class="card-header">
                    <h2 class="title">Create New Organization</h2>
                    <p class="subtitle">Enter organization details below</p>
                </div>

                <div class="card-body">
                    <div class="form-grid">
                        <!-- Organization Name Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Organization Name <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-building icon"></i>
                                    <input class="modern-input" v-model="org_data.organization_name" placeholder="Enter organization name">
                                </div>
                            </label>
                        </div>

                        <!-- Address Section -->
                        <div class="form-section full-width">
                            <label class="modern-label">
                                <span class="label-text">Address <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-map-marker-alt icon"></i>
                                    <textarea class="modern-input" v-model="org_data.hq_address" placeholder="Enter address"></textarea>
                                </div>
                            </label>
                        </div>

                        <!-- City Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">City <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-city icon"></i>
                                    <input class="modern-input" v-model="org_data.city" placeholder="Enter city">
                                </div>
                            </label>
                        </div>

                        <!-- State Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">State <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-map icon"></i>
                                    <select class="modern-input" v-model="org_data.state">
                                        <option value="">Select state</option>
                                        <option v-for="(data, key) in states" :key="key" :value="data">{{ data }}</option>
                                    </select>
                                </div>
                            </label>
                        </div>

                        <!-- Postal Code Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Postal Code <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-mail-bulk icon"></i>
                                    <input class="modern-input" v-model="org_data.postal_code" placeholder="Enter postal code">
                                </div>
                            </label>
                        </div>

                        <!-- Contact Email Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Contact Email <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-envelope icon"></i>
                                    <input type="email" class="modern-input" v-model="org_data.contact_email" placeholder="Enter contact email">
                                </div>
                            </label>
                        </div>

                        <!-- Contact Phone Section -->
                        <div class="form-section">
                            <label class="modern-label">
                                <span class="label-text">Contact Phone <span class="required">*</span></span>
                                <div class="input-with-icon">
                                    <i class="fas fa-phone icon"></i>
                                    <input class="modern-input" v-model="org_data.contact_phone" placeholder="Enter contact phone">
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="button-group">
                        <button class="modern-button cancel" @click="$router.push('/organizations')">
                            <i class="fas fa-times"></i> Cancel
                        </button>
                        <button class="modern-button submit" @click="submit()">
                            <i class="fas fa-plus"></i> Create Organization
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { registerOrg } from "@/services/orgServices"
import TitleHeader from "@/components/TitleHeader.vue"
import StatesJSON from "@/assets/states.json"
import CitiesJSON from "@/assets/cities.json"

export default {
    components: {
        TitleHeader
    },

    data() {
        return {   
            org_data: {
                organization_name: "",
                hq_address: "",
                city: "",
                state: "",
                country: "Malaysia",
                postal_code: "",
                latitude: "",
                longitude: "",
                contact_email: "",
                contact_phone: "",
                subscription_plan: "",
                subscription_type: "",
                subscription_status: ""
            },
            states: StatesJSON,
            cities: CitiesJSON,
        }
    },

    methods: {
        async submit() {
            try {
                const response = await registerOrg(this.org_data);
                if(response) {
                    this.$router.push('/org');
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.register-container {
    min-height: 100vh;
    padding: 2rem;
    background-color: #f8f9fa;
}

.form-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.modern-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0,0,0,0.08);
    border: none;
    padding: 2rem;
}

.card-header {
    border: none;
    background: none;
    padding: 0 0 2rem 0;
}

.title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.subtitle {
    color: #6c757d;
    font-size: 1rem;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
}

.form-section {
    position: relative;
}

.full-width {
    grid-column: 1 / -1;
}

.modern-label {
    width: 100%;
}

.label-text {
    display: block;
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: 500;
}

.required {
    color: #dc3545;
    margin-left: 4px;
}

.input-with-icon {
    position: relative;
}

.icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
}

.modern-input {
    width: 100%;
    padding: 0.8rem 1rem 0.8rem 2.8rem;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.modern-input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(15, 186, 178, 0.1);
    outline: none;
}

textarea.modern-input {
    min-height: 100px;
    resize: vertical;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.modern-button {
    padding: 0.8rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.submit {
    background: var(--primary-color);
    color: white;
}

.submit:hover {
    background: var(--primary-hover);
    transform: translateY(-2px);
}

.cancel {
    background: #dc3545;
    color: white;
}

.cancel:hover {
    background: #c82333;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .register-container {
        padding: 1rem;
    }

    .modern-card {
        padding: 1.5rem;
    }

    .button-group {
        flex-direction: column;
    }

    .modern-button {
        width: 100%;
        justify-content: center;
    }
}
</style>
