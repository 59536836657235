<template>
    <div v-if="show" class="overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Register Integration Key</h3>
          <button class="close-btn" @click="$emit('close')">×</button>
        </div>
  
        <div class="modal-body">
            <div class="error-message" v-if="errorMessage">
                {{ errorMessage }}
              </div>
          <!-- User Search with Autocomplete -->
          <div class="input-group">
            <label>Select User</label>
            <input
              type="text"
              v-model="search"
              placeholder="Search users..."
              class="search-input"
              @input="handleSearchInput"
              @focus="showDropdown = true"
            />
            
            <div class="dropdown" v-if="showDropdown && filteredUsers.length && search">
              <div 
                v-for="user in filteredUsers" 
                :key="user.uuid"
                class="dropdown-item"
                @click="selectUser(user)"
              >
                <div class="user-info">
                  <div class="user-name">{{ user.full_name }}</div>
                  <div class="user-details">
                    <span>{{ user.clinic_name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Description Input -->
          <div class="input-group">
            <label>Description</label>
            <textarea
              v-model="description"
              placeholder="Enter description..."
              class="description-input"
              rows="3"
            ></textarea>
          </div>
  
          <!-- Submit Button -->
          <button 
            class="submit-btn"
            :disabled="!selectedUser"
            @click="handleSubmit"
          >
            Register Integration Key
          </button>
        </div>
      </div>
    </div>
</template>
  
<script>
import { getAllUser } from '@/services/userServices'
import { registerIntegration } from '@/services/integrationServices'

export default {
props: {
    show: {
    type: Boolean,
    default: false
    }
},
data() {
    return {
    search: '',
    description: '',
    selectedUser: null,
    users: [],
    showDropdown: false,
    loading: false,
    errorMessage: ''
    }
},
computed: {
    filteredUsers() {
    if (!this.search) return []
    return this.users.filter(user => 
        user.full_name.toLowerCase().includes(this.search.toLowerCase()) ||
        user.clinic_name.toLowerCase().includes(this.search.toLowerCase())
    )
    }
},
methods: {
    handleSearchInput() {
    if (this.search.length > 2) {
        this.fetchUsers()
    }
    },
    async fetchUsers() {
    try {
        const response = await getAllUser()
        this.users = response.users
    } catch (error) {
        console.error('Error fetching users:', error)
    }
    },
    selectUser(user) {
    this.selectedUser = user
    this.search = user.full_name
    this.showDropdown = false
    },
    async handleSubmit() {
        try {
            this.loading = true
            this.errorMessage = ''
            
            // Validate only user_uuid
            if (!this.selectedUser?.uuid) {
            this.errorMessage = 'Please select a user'
            return
            }

            const payload = {
                user_uuid: this.selectedUser.uuid,
                description: this.description.trim() || null // Description is optional
            }

            const response = await registerIntegration(payload)
            this.$emit('close')
            this.$emit('success')
        } catch (error) {
            this.errorMessage = error.response?.data?.message || 'Failed to register integration key'
        } finally {
            this.loading = false
        }
    }
},
mounted() {
    this.fetchUsers()
    document.addEventListener('click', (e) => {
    if (!this.$el.contains(e.target)) {
        this.showDropdown = false
    }
    })
},
beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown)
}
}
</script>
  
<style scoped>
.overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 9999;
}

.modal-content {
background: white;
border-radius: 8px;
width: 90%;
max-width: 500px;
padding: 20px;
}

.modal-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
}

.close-btn {
background: none;
border: none;
font-size: 24px;
cursor: pointer;
}

.input-group {
margin-bottom: 20px;
position: relative;
}

.input-group label {
display: block;
margin-bottom: 5px;
font-weight: bold;
}

.search-input,
.description-input {
width: 100%;
padding: 10px;
border: 1px solid #ddd;
border-radius: 4px;
font-size: 16px;
}

.dropdown {
position: absolute;
top: 100%;
left: 0;
right: 0;
background: white;
border: 1px solid #ddd;
border-radius: 4px;
max-height: 200px;
overflow-y: auto;
z-index: 1000;
}

.dropdown-item {
padding: 10px;
cursor: pointer;
}

.dropdown-item:hover {
background: #f5f5f5;
}

.user-info {
display: flex;
flex-direction: column;
}

.user-name {
font-weight: bold;
}

.submit-btn {
width: 100%;
padding: 10px;
background: var(--primary-color);
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.submit-btn:disabled {
background: #cccccc;
cursor: not-allowed;
}
</style>
  
<style scoped>
.error-message {
  color: #dc3545;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: #f8d7da;
}
</style>