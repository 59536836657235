import { createRouter, createWebHistory } from 'vue-router';

import Login from '../pages/Login.vue';
import ClinicsList from '../pages/Clinics/Clinics.vue';
import ClinicsEdit from '../pages/Clinics/ClinicsEdit.vue';
import UsersList from '../pages/Users/Users.vue';
import UsersRegister from '../pages/Users/UsersRegister.vue';
import UsersEdit from '../pages/Users/UsersEdit.vue';
import OrgList from '../pages/Organization/Organization.vue';
import OrgRegister from '../pages/Organization/OrganizationRegister.vue';
import OrgEdit from '../pages/Organization/OrganizationEdit.vue';
import AuditList from '../pages/Audits/Audits.vue';
import Integrations from '../pages/Integrations/Integrations.vue';
import Dashboard from '../pages/Dashboard/Dashboard.vue'
import Error from '../pages/Error.vue';
import { useAuthStore } from '@/stores/authStore';
import LoginAudits from '../pages/LoginAudits/LoginAudits.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/clinics',
    name: 'clinics-list',
    component: ClinicsList,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
    }
  },
  {
    path: '/clinics/edit/:id',
    name: 'clinics-edit',
    component: ClinicsEdit,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
    }
  },
  {
    path: '/users',
    name: 'users-list',
    component: UsersList,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
    }
  },
  {
    path: '/users/register',
    name: 'users-register',
    component: UsersRegister,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
    }
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: UsersEdit,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      //allowedRoles: ['clinic_admin']
    }
  },
  {
    path: '/org',
    name: 'org-list',
    component: OrgList,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/org/register',
    name: 'org-register',
    component: OrgRegister,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/org/edit/:id',
    name: 'org-edit',
    component: OrgEdit,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/audits',
    name: 'audits',
    component: AuditList,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: Integrations,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/login-audits',
    name: 'login-audits',
    component: LoginAudits,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
      allowedRoles: ['admin']
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { 
      requiresAuth: true, 
      requiresAdmin: true, 
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: 'error',
    component: Error, // Catch-all route for 404 or unauthorized access
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  authStore.loadTokensFromStorage(); // Load tokens from localStorage

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const isAuthenticated = authStore.accessToken;

  if (requiresAuth) {
    if (!isAuthenticated) {
      next('/login');  // If no token is present, redirect immediately
      return;
    }

    try {
      await authStore.refreshAccessToken();
      await authStore.fetchUserRoles();  // Fetch user details to check role

      if (requiresAdmin && authStore.userRole !== 'admin' && authStore.userRole !== 'clinic_admin') {
        authStore.logout();
        return next('/login');  // Redirect to login if user is not an admin
      }
    } catch (error) {
      console.error("Token refresh or user detail fetch failed, logging out.");
      authStore.logout();  // Log out and stop retrying
      return next('/login');
    }
  }

  if (to.name === 'login' && isAuthenticated) {
    next({ name: 'users-list' });
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  
  if (to.path === '/login') {
    next();
    return;
  }

  if (!authStore.accessToken) {
    next('/login');
    return;
  }

  // Check route permissions
  if (to.meta.allowedRoles && !to.meta.allowedRoles.includes(authStore.userRole)) {
    next('/');
    return;
  }

  next();
});



export default router;
