import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export const getUser = async (retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getUser(true); // Retry after refreshing
        }
        throw error;
    }
}

export const getAllUser = async (retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${await authStore.get_url()}/users`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllUser(true); // Retry after refreshing
        }
        throw error;
    }
}  

export const getUserUuid = async (retryAttempt = false) => {
  const authStore = useAuthStore();
  let arrayRes = [];

  try {
      const response = await axios.get(`${await authStore.get_url()}/users`, {
      headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
      },
      });

      for (const [key, value] of Object.entries(response.data.users)) {
        arrayRes[value.uuid] = value.full_name
      }
  
      return arrayRes;
  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getUserUuid(true); // Retry after refreshing
      }
      throw error;
  }
}  

export const registerUser = async (userData, retryAttempt = false) => {
    const authStore = useAuthStore();

    console.log(userData);

    try {
      const response = await axios.post(`${await authStore.get_url()}/register`, userData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerUser(userData, true); // Retry after refreshing
        }
        throw error;
    }
};

export const updateLocationUser = async (data, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${await authStore.get_url()}/update_user_locations`, data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return updateLocationUser(data, true); // Retry after refreshing
        }
        throw error;
    }
  };

export const editUser = async (input_data, id, retryAttempt = false) => {
const authStore = useAuthStore();

try {
    const response = await axios.put(`${await authStore.get_url()}/user/${ id }`, input_data, {
    headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
    },
    });

    return response.data; // Return response data upon successful registration
    } catch (error) {
    if (error.response?.status === 401 && !retryAttempt) {
        // Token might be expired, attempt to refresh
        await authStore.refreshAccessToken();
        return updateLocationUser(input_data, id, true); // Retry after refreshing
    }
    throw error;
}
};

export const getCurrentUser = async (uuid, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`${await authStore.get_url()}/user/${uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentUser(uuid, true); // Retry after refreshing
        }
        throw error;
    }
};

export const deactivateUser = async (uuid, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.put(`${await authStore.get_url()}/deactivate_user/${uuid}`, {}, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return deactivateUser(uuid, true); // Retry after refreshing
        }
        throw error;
    }
};


