<script>
import { getAuditCase } from '@/services/auditServices'
import { BriefcaseBusinessIcon } from 'lucide-vue-next'
import ImageViewer from '@/components/ImageViewer.vue'
import AuditsSubmitOverlay from './components/AuditsSubmitOverlay.vue'

export default {
  name: 'Audits',
  components: {
    BriefcaseBusinessIcon,
    ImageViewer,
    AuditsSubmitOverlay
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      images: [],
      loading: false,
      hasSearched: false,
      currentPage: 1,
      limit: 10,
      audit_status: false,
      audit_image: true,
      totalCases: 0,
      totalPages: 0,
      showImageViewer: false,
      selectedImage: {},
      selectedMedia: {},
      showAuditOverlay: false
    }
  },

  methods: {
    async fetchAuditCases() {
      if (!this.startDate || !this.endDate) {
        alert('Please select both start and end dates')
        return
      }

      this.loading = true
      this.hasSearched = true

      const adjustedEndDate = new Date(this.endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      try {
        const result = await getAuditCase(
          new Date(this.startDate),
          adjustedEndDate,
          this.audit_status,
          this.audit_image,
          this.currentPage,
          this.limit
        )

        this.images = result.images.reverse()
        console.log(this.images)
        this.totalCases = result.total_cases
        this.totalPages = result.total_pages
      } catch (error) {
        console.error('Error fetching audit cases:', error.response.data.detail)
        if(error.response.data.detail == 'No images found for the specified criteria'){
          this.images = []
        }
      } finally {
        this.loading = false
      }
    },

    async changePage(newPage) {
      this.currentPage = newPage
      await this.fetchAuditCases()
    },

    convertToMalaysiaTime(utcDateTime) {
        // Parse the UTC datetime string
        const utcDate = new Date(utcDateTime + 'Z'); // Add 'Z' to indicate UTC
        // Malaysia Time is UTC +8
        const malaysiaTimeOffset = 8 * 60; // Offset in minutes
        // Create a new Date object with the Malaysia Time offset
        const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset * 60 * 1000);
        // Format the date to a readable string
        return malaysiaDate.toISOString().replace('T', ' ').slice(0, 19);
    },

    formatDate(dateString) {
      const convertedDate = this.convertToMalaysiaTime(dateString)
      return new Date(convertedDate).toLocaleString()
    },

    getAIStatusClass(status) {
      return {
        'status-normal': status === 'normal',
        'status-abnormal': status === 'abnormal'
      }
    },

    getAuditStatusClass(grade) {
      return {
        'status-pending': !grade,
        'status-completed': grade
      }
    },

    handleAuditSubmit(image){
      console.log(image);
      this.selectedMedia = image
      this.showAuditOverlay = true
    },

    closeAuditOverlay() {
      this.showAuditOverlay = false
      this.selectedMedia = null
    },

    handleAuditSubmitted() {
      // Refresh your data after successful submission
      this.fetchAuditCases()
    }

  },

  computed: {
    visiblePages() {
      let pages = [];
      let start = Math.max(2, this.currentPage - 2);
      let end = Math.min(this.totalPages - 1, this.currentPage + 2);

      if (this.currentPage <= 4) {
        start = 2;
        end = Math.min(6, this.totalPages - 1);
      }

      if (this.currentPage >= this.totalPages - 3) {
        start = Math.max(this.totalPages - 5, 2);
        end = this.totalPages - 1;
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    }
  },

  mounted() {
    const today = new Date()
    this.endDate = today.toISOString().split('T')[0]
    
    const sevenDaysAgo = new Date(today)
    sevenDaysAgo.setDate(today.getDate() - 7)
    this.startDate = sevenDaysAgo.toISOString().split('T')[0]

    this.fetchAuditCases()
  }
}
</script>

<template>
  <div class="text-center mt-4">
    <h3><BriefcaseBusinessIcon/> Audit Cases</h3>
  </div>

  <ImageViewer
    v-model:show="showImageViewer"
    :image-url="selectedImage.SignedUrl"
    :width="selectedImage.Width"
    :height="selectedImage.Height"
  />

  <AuditsSubmitOverlay
    v-if="showAuditOverlay"
    :audit_data="selectedMedia"
    @close="closeAuditOverlay"
    @audit-submitted="handleAuditSubmitted"
  />

  <div class="audit-container">
    <div class="audit-card">
      <header class="audit-header">
        <div class="audit-stats">
          <div class="stat-item" v-if="totalCases > 0">
            <span class="stat-label">Total Cases:</span>
            <span class="stat-value">{{ totalCases }}</span>
          </div>
        </div>
      </header>

      <div class="date-filters">
        <div class="date-input-group">
          <label>Start Date</label>
          <input type="date" v-model="startDate" class="custom-input">
        </div>
        <div class="date-input-group">
          <label>End Date</label>
          <input type="date" v-model="endDate" class="custom-input">
        </div>
      </div>

      <div class="filters-section">
        <div class="filter-group">
          
          <div class="advanced-filters">
            <div class="filter-item">
              <label>Items per page</label>
              <select v-model="limit" class="custom-input mx-2">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="15">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </div>

        <div class="filter-item">
          <label>Audit Status</label>
          <select v-model="audit_status" class="form-input mx-2">
            <option :value="null">All</option>
            <option :value="true">Audited</option>
            <option :value="false">Unaudited</option>
          </select>
        </div>

        <button @click="fetchAuditCases" class="search-button">
          <i class="fas fa-search"></i> Search
        </button>
      </div>

      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
      </div>

      <div v-else-if="images.length" class="results-section">
        <div class="table-responsive">
          <table class="custom-table">
            <thead>
              <tr>
                <th class="text-center">Image</th>
                <th class="text-center">Image Uuid</th>
                <th class="text-center">AI Analysis</th>
                <th class="text-center">Audit Status</th>
                <th class="text-center">Details</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="image in images" :key="image.MediaUuid">
                <td class="image-cell text-center">
                  <div class="image-container">
                    <img :src="image.SignedUrl" class="thumbnail" :alt="image.FileName">
                    <button class="zoom-button"  @click="showImageViewer = true; selectedImage = image">
                      <i class="fas fa-search-plus"></i>
                    </button>
                  </div>
                </td>
                <td class="details-cell">
                  <div class="detail-item d-flex justify-content-start text-start">
                    <strong class="mx-1">{{ image.MediaUuid.substring(0, 6) }}...{{ image.MediaUuid.slice(-4) }}</strong>
                  </div>
                </td>
                <td class="ai-analysis-cell text-center">
                  <div class="analysis-badge" :class="getAIStatusClass(image.AIAbnormality)">
                    {{ image.AIAbnormality}}
                  </div>
                  <div class="confidence-bar">
                    <div class="confidence-fill" :style="{ width: `${image.AIConfidence * 100}%` }"></div>
                    <span>{{ (image.AIConfidence * 100).toFixed(1) }}%</span>
                  </div>
                </td>
                <td class="audit-status-cell text-center">
                  <span class="audit-badge" :class="getAuditStatusClass(image.AuditGrade)">
                    {{ image.AuditGrade ? image.AuditGrade.replace('_', ' ').charAt(0).toUpperCase() + image.AuditGrade.replace('_', ' ').slice(1) : 'Pending Audit' }}
                  </span>
                </td>
                <td class="details-cell">
                  <div class="detail-item d-flex justify-content-start text-start">
                    <strong class="mx-1">Created: </strong> {{ formatDate(image.created_at) }}
                  </div>
                  <div class="detail-item d-flex justify-content-start text-start">
                    <strong class="mx-1">Brand: </strong> {{ image.InstrumentBrand }}
                  </div>
                  <div class="detail-item d-flex justify-content-start text-start">
                    <strong class="mx-1">Eye Type: </strong> {{ image.EyeType === 0 ? 'Left' : 'Right' }}                  
                  </div>
                </td>
                <td class="action-cell text-center">
                  <!-- <button class="audit-button" @click="handleAudit(image)"> -->
                    <button class="audit-button" @click="handleAuditSubmit(image)" v-if="!image.AuditAbnormality">
                      <i class="fas fa-pen"></i>
                      <span>Audit</span>
                    </button>
                    <button class="audit-button" @click="handleAuditSubmit(image)" v-else>
                      <i class="fa fa-eye"></i>
                      <span>View</span>
                    </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination-section">
          <div class="pagination-controls">
            <button 
              :disabled="currentPage === 1" 
              @click="changePage(currentPage - 1)" 
              class="pagination-button"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
        
            <div class="page-numbers">
              <!-- First page -->
              <button 
                v-if="totalPages > 0"
                @click="changePage(1)"
                class="page-number-button"
                :class="{ active: currentPage === 1 }"
              >
                1
              </button>
        
              <!-- Left ellipsis -->
              <span v-if="currentPage > 4" class="ellipsis">...</span>
        
              <!-- Pages around current page -->
              <button 
                v-for="page in visiblePages" 
                :key="page"
                @click="changePage(page)"
                class="page-number-button"
                :class="{ active: currentPage === page }"
              >
                {{ page }}
              </button>
        
              <!-- Right ellipsis -->
              <span v-if="currentPage < totalPages - 3" class="ellipsis">...</span>
        
              <!-- Last page -->
              <button 
                v-if="totalPages > 1"
                @click="changePage(totalPages)"
                class="page-number-button"
                :class="{ active: currentPage === totalPages }"
              >
                {{ totalPages }}
              </button>
            </div>
        
            <button 
              :disabled="currentPage === totalPages" 
              @click="changePage(currentPage + 1)" 
              class="pagination-button"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>

      <div v-else-if="hasSearched" class="no-results">
        <i class="fas fa-search"></i>
        <p>No audit cases found for the selected criteria</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.audit-container {
  padding: 1rem;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .audit-container {
    padding: 2rem;
  }
}

.audit-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  padding: 1.5rem;
  margin: 0 auto;
}

.audit-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .audit-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.filters-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .filters-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.advanced-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.confidence-bar {
  background: #e2e8f0;
  height: 8px;
  border-radius: 4px;
  position: relative;
  margin-top: 0.5rem;
}

.confidence-fill {
  background: #2cbbb2;
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.analysis-badge, .audit-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-normal { background: #dcfce7; color: #166534; }
.status-abnormal { background: #fee2e2; color: #991b1b; }
.status-pending { background: #fef3c7; color: #92400e; }
.status-completed { background: #dbeafe; color: var(--primary-color); }

.thumbnail {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.page-info {
  background: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: #475569;
  font-weight: 500;
}

.audit-container {
  padding: 2rem;
  min-height: 100vh;
}

.audit-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.audit-title {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
}

.date-filters {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;  /* Default for mobile */
}

/* Add media query for larger screens */
@media (min-width: 768px) {
  .date-filters {
    grid-template-columns: 3fr 3fr;  /* Two columns for desktop */
  }
}

.date-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-input-group label {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 500;
}

.custom-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
}

.custom-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.search-button {
  background: var(--primary-color);
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.search-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.loader-container {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.loader {
  width: 48px;
  height: 48px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table-container {
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table th {
  background: #f8fafc;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #475569;
  border-bottom: 2px solid #e2e8f0;
  white-space: nowrap;
}

.custom-table td {
  padding: 1rem;
  color: #64748b;
  border-bottom: 1px solid #e2e8f0;
}

.custom-table tbody tr:hover {
  background-color: #f8fafc;
}

.no-results {
  text-align: center;
  padding: 3rem;
  color: #64748b;
  font-size: 1.1rem;
}

.thumbnail {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.page-info {
  background: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: #475569;
  font-weight: 500;
}
</style>

<style scoped>
.text-center {
  text-align: center;
}

.custom-table th,
.custom-table td {
  padding: 1rem;
  vertical-align: middle;
}

.analysis-badge,
.audit-badge {
  display: inline-block;
}
</style>

<style scoped>
.pagination-section {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-numbers {
  display: flex;
  gap: 0.1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-button,
.page-number-button {
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #64748b;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-number-button.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.pagination-button:hover:not(:disabled),
.page-number-button:hover:not(.active) {
  background: #f1f5f9;
  color: #1e293b;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ellipsis {
  color: #64748b;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .page-numbers {
    max-width: 200px;
    overflow-x: auto;
    padding: 0.5rem;
  }

  .pagination-button,
  .page-number-button {
    min-width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }
}
</style>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.zoom-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.image-container:hover .zoom-button {
  opacity: 1;
}

.audit-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.audit-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.action-cell {
  width: 120px;
}

.details-cell{
  font-size: 0.85rem;
}
</style>

<style scoped>
.status-filter {
  margin: 1rem 0;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  width: 200px;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}
</style>